import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import CustomDialog from "../../../../components/mui/dialog";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomLabel from "../../../../components/mui/custom-label";
import { IDailyReport, IDailyReportFields } from "../../../../interfaces/configuration/daily-report";
import { dailyReportValidation } from "../../../../validations/configuration/daily-report";
import { IErrorResponse, IRole } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import { DailyReportService, RoleService } from "../../../../services";
import { capitalize } from "../../../../utilities/helper";
import { useState, KeyboardEvent, SyntheticEvent } from "react";
import EmailSuggestion from "../../../../components/mui/email-suggestion";
import { validateEmail } from "../../../../validations/shared";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useEffect } from "react";


interface outletProps {
  refetchDailyReports: () => void;
}

const ManageDailyReport = () => {

  const { updateDailyReportRoles, getDailyReportRole } = DailyReportService();
  const { handleSubmit, control, trigger, resetField, setValue, getValues, watch, formState: { errors } } = useForm<IDailyReport>({
    resolver: joiResolver(dailyReportValidation),
    defaultValues: {
      _roles: [],
      reportType: "",
      duration: "",
      bcc: [],
      cc: [],
      ccText: "",
      bccText: ""
    }
  });
  const [searchParams ] = useSearchParams();
  const { getPartialRoles } = RoleService();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const { id } = useParams();
  const rolesData = useQuery({
    queryKey: ["partialRoles"],
    queryFn: () => getPartialRoles({ admin: true }),
  });
  const roles = rolesData && rolesData.data && rolesData.data.data || [];
  const onClose = () => {
		navigate({pathname: "/configurations/daily-report-roles", search: searchParams.toString()});
	};

  const reportData = useQuery({
    queryKey: ["daily-report-roles"],
    queryFn: () => getDailyReportRole({
      _id: id })
  });

  const fields: IDailyReportFields[] = [
    {
      label: "Report Type",
      name: "reportType",
      type: "input",
      required: true,
      disabled: true
    },
    {
      label: "Roles",
      name: "_roles",
      type: "select",
      required: true,
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "CC",
      name: "ccText",
      placeholder: "CC",
      type: "input"
    },
    {
      label: "BCC",
      name: "bccText",
      placeholder: "BCC",
      type: "input"
    },
    {
      label: "Time Period",
      name: "duration",
      type: "input",
      required: true,
      disabled: true
    }
  ];

  useEffect(() => {
    const data = reportData && reportData.data && reportData.data.data;
    if(data) {
      const roleIds = data._roles.map((role) => role._id);
      setValue("reportType", data.reportType || "");
      setValue("duration", data.duration || "");
      setValue("_roles", roleIds || []);
      setValue("cc", data.cc || []);
      setValue("bcc", data.bcc || []);
      setUserRoles(roleIds || []);
    }
  }, [(reportData && reportData.data && reportData.data.data )]);
  
  const onSubmit = async (data: IDailyReport) => {
    try {
      const payload = {
        _id: id,
        _roles: userRoles,
        cc: data.cc,
        bcc: data.bcc,
        reportType: data.reportType,
        duration: data.duration
      };
      const response = await updateDailyReportRoles(payload);
      snackbar(response.message, "info");
      outlet.refetchDailyReports();
      navigate({pathname: "/configurations/daily-report-roles", search: searchParams.toString()});
    } catch(error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>, key: string) => {
    if (key === "ccText" || key === "bccText") {
      const err = validateEmail(getValues(key));
      if (err.error) {
          return;
      }
      const enteredEmail = getValues(key) ? String(getValues(key)) : "";

      if(enteredEmail.trim() !== "" && event.key === "Enter") {
        if(key === "ccText") {
          const prev = getValues("cc") ? getValues("cc") : [];
          const payload = [...prev, enteredEmail];
          setValue("cc", [...new Set(payload)]);
          resetField(key);
        } else if(key === "bccText") {
          const prev = getValues("bcc") ? getValues("bcc") : [];
          const payload = [...prev, enteredEmail];
          setValue("bcc", [...new Set(payload)]);
          resetField(key);
        }
      }
    }
  };

  const handleEmailSelect = (email: string, name: string) => {
    if(["ccText", "bccText"].includes(name)) {
      if (name === "ccText") {
        const prev = getValues("cc") ? getValues("cc") : [];
        const payload = [...prev, email];
        setValue("cc", [...new Set(payload)]);
        resetField(name);
        
      } else if (name === "bccText") {
        const prev = getValues("bcc") ? getValues("bcc") : [];
        const payload = [...prev, email];
        setValue("bcc", [...new Set(payload)]);
        resetField(name);
      }
    }
  };

  const removeChip = (field: string, data: string) => {
    if (field === "ccText") {
      let payload = getValues("cc");
      payload = payload.filter(email => email !== data);
      setValue("cc", payload);
      trigger("cc");
    } else if (field === "bccText") {
      let payload = getValues("bcc");
      payload = payload.filter(email => email !== data);
      setValue("bcc", payload);
      trigger("bcc");
    } else if(field === "_roles") {
      setUserRoles(userRoles.filter((item) => item !== data));
    }
  };

  const setRolesInArray = (event: SyntheticEvent<Element, Event>, value: IRole[], field: string ) => {
    if(field === "_roles" && !userRoles.includes(value[0]._id)) {
      const roleIds = value.map((role) => role._id);
      setUserRoles([...userRoles, ...roleIds]);
    }
  };

  const handleKeyDown = (event : React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
        event.preventDefault();
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
        if (name && ["ccText", "bccText"].includes(name)) {
            trigger(name);
        }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <CustomDialog
      title="Edit Daily Report Roles"
      isOpen={!! id}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      size="md"
    >
      <Grid container spacing={4} onKeyDown={handleKeyDown}>
      
          {
            fields.map(field => {
              if(field.name === "ccText" || field.name === "bccText") {
                return (<Grid key={field.name} item xs={6} >
                  <EmailSuggestion
                    control={control}
                    label={field.label}
                    name={field.name}
                    value={getValues(field.name)}
                    placeholder={field.placeholder}
                    error={!! errors[field.name]}
                    helperText={errors[field.name]?.message}
                    onEmailSelect={handleEmailSelect}
                    onKeyUp={handleKeyPress}
                  />
                  {
                    <Box>
                      {
                        field.name === "ccText" && getValues("cc") && getValues("cc").map(email => (
                          <Chip
                            key={email}
                            label={email}
                            onDelete={() => removeChip(field.name, email)}
                            color="primary"
                            variant="outlined"
                            sx={{ margin: "5px" }}
                          />
                        )) ||
                        field.name === "bccText" && getValues("bcc") && getValues("bcc").map(email => (
                          <Chip
                            key={email}
                            label={email}
                            onDelete={() => removeChip(field.name, email)}
                            color="primary"
                            variant="outlined"
                            sx={{ margin: "5px" }}
                          />
                        ))
                      }
                    </Box>
                  }
                </Grid>);
              }
              if(field.type === "input") {
                return (<Grid key={field.label} item xs={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field.required} />}
                    disabled={field.disabled}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={!! errors[field.name]}
                      helperText={errors[field.name]?.message}
                      inputProps={{ maxLength: 80 }}
                      
                      {...prop.field}
                    />}
                  />
                </Grid>);
              }

              if(field.type === "select") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    options={roles || []}
                    getOptionLabel={(option) => capitalize(option.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        placeholder={field.placeholder}
                        disabled={!! field.disabled}
                        error={errors[field.name] && true}
                        helperText={errors[field.name]?.message}
                      />
                    )}
                    disabled={!!field.disabled}
                    value={[]}
                    onChange={(e, value) =>
                      setRolesInArray(e, value, field.name)
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {capitalize(option.name)}
                      </li>
                    )}
                    multiple
                  />
                  {
                    userRoles.map(data => (
                      <Chip
                            key={data}
                            label={capitalize(roles.filter((role) => role._id === data)?.[0]?.name)}
                            onDelete={() => removeChip(field.name, data)}
                            color="primary"
                            variant="outlined"
                            sx={{ margin: "5px" }}
                      />
                    ))
                  }
              </Grid>
              );
              }
            })
          }
        
      </Grid>
    </CustomDialog>
  );
};

export default ManageDailyReport;