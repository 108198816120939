export const authentication = "auth";
export const analytics = "analytics";
export const lead = "lead";
export const broadcast = "broadcast";
export const broadcastGroup = "broadcast-group";
export const offered = "lead";
export const offereAction = "offered";
export const kpi = "kpi";
export const trash = "lead";
export const trashBlog = "cms/blog";
export const interview = "interview";
export const rescheduleInterview = "interview/reschedule-interview";
export const review = "review";
export const report = "reports-analytics";

//CPD
export const cpdDashboard = "college-recruitment";
export const cpdDrive = "college-recruitment";

// Careers
export const job = "career/job";
export const employeeReview = "career/employee-review";
export const addemployeeReview = "career/employee-review";
export const getemployeeReview = "career/employee-review";
export const updateemployeeReview = "career/employee-review";
export const collegeImage = "career/college-image";
export const removecollegeImage = "career/college-image";
export const officeEmployeeImage = "career/employee-image";
export const addofficeEmployeeImage = "career/employee-image";
export const getofficeEmployeeImage = "career/employee-image";
export const updateofficeEmployeeImage = "career/employee-image";
export const removeofficeEmployeeImage = "career/employee-image";
export const faq = "career/faq";
export const highlight = "career/highlight";
export const publicUrl = "public";

// Configurations
export const user = "configuration/user";
export const role = "configuration/user-role";
export const resourceRequestRole = "configuration/resource-request-role";
export const team = "configuration/department";
export const engagement = "configuration/job-type";
export const jobPosition = "configuration/job-position";
export const jobTitle = "configuration/job-title";
export const template = "configuration/template";
export const status = "configuration/status";
export const college = "configuration/college";
export const parameter = "configuration/interview-parameter";
export const reviewParameter = "configuration/review-parameter";
export const onboardUser = "configuration/onboard-user";
export const coderByteLink = "configuration/coderbyte-links";
export const businessUnit = "configuration/business-unit";
export const test = "config-cms/test";
export const dailyReportRoles = "daily-report-emails";

//Skill Matrix
export const skillMatrix = "skill-matrix";

//Settings
export const changepassword = "configuration/user/change-password";

//Common Email
export const commonEmail = "common";
export const notification = "notification";

//Content Layout
export const categoryList = "config-cms/category/list";
export const strapiCategoryList = "config-cms/strapi/category/list";
export const category = "config-cms/category";

export const subCategoryList = "config-cms/sub-category/list";
export const subCategory = "config-cms/sub-category";

export const keywordList = "config-cms/keyword/list";
export const keyword = "config-cms/keyword";

export const productList = "config-cms/product-title/list";
export const product = "config-cms/product-title";

export const partsOfFunnelList = "config-cms/funnel-parts/list";
export const partsOfFunnel = "config-cms/funnel-parts";

export const goalsList = "config-cms/goal/list";
export const goal = "config-cms/goal";

export const typesOfContentList = "config-cms/content-types/list";
export const typesOfContent = "config-cms/content-types";

export const scoreDataList = "config-cms/score-data/list";
export const scoreData = "config-cms/score-data";

export const authorList = "config-cms/author/list";
export const strapiAuthorList = "config-cms/strapi/author/list";
export const author = "config-cms/author";

export const writerReportRoles = "config-cms/writer-report-roles";

export const questionTags = "config-cms/tags";
export const questionLanguages = "config-cms/languages";
export const technicalQuestion = "config-cms/technical-question";
export const testSession = "config-cms/test-session";
export const assessments = "config-cms/assessment";
export const tests = "config-cms/test";
export const assessmentInstructions = "config-cms/assessments-instruction";


export const projectDataList = "cms/quarter/list";
export const project = "cms/quarter";

export const quarterTargetDataList = "cms/quarter-targets/list";
export const quarterTarget = "cms/quarter-targets";

export const blog = "cms/blog";
export const blogList = "cms/blog/list";

export const blogImage = "cms/blog-image";
export const blogImageList = "cms/blog-image/list";

export const blogScore = "cms/blog-score";
export const blogScoreList = "cms/blog-score/list";

export const blogKeywordDensity = "cms/blog/keyword-density";


export const blogComment = "cms/comments";
export const blogCommentReply = "cms/comments/reply";
export const blogCommentList = "cms/comments/list";


export const blogContent = "cms/blog-content";
export const blogContentList = "cms/blog-content/list";
export const blogContentVersionsList = "cms/blog-content/versions";

export const productMappingList = "config-cms/product-mapping/list";
export const productMapping = "config-cms/product-mapping";
export const checkList = "config-cms/check-list/list";

export const blogImportSample = "cms/blog/sample";

export const blogImport = "cms/blog/import";

export const cmsReport = "cms/blog-analytics";

export const cmsHome = "cms/blog-home/";

export const cmsHomeActivity = "cms/timeline/fetch-timeline";

export const quarterTargetSample = "cms/quarter-targets/sample";
export const quarterTargetImport = "cms/quarter-targets/import";

//Resource Request
export const resourceRequest = "resource";
export const aiParser = "resume-parser";

export const vendor = "vendor";
export const vendorService = "vendor/service";
export const vendorCategory = "vendor/vendor-category";
export const vendorTransaction = "vendor/transaction";
export const vendorPaymentMode = "vendor/payment-mode";
export const vendorContract = "vendor/contract-detail";
export const vendorContactPerson = "vendor/contact-person";
export const vendorNote = "vendor/note";