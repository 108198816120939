import Joi from "joi";
import { only_string, required } from "../../shared";

export const AddCandidateValidation = Joi.object({
    jobId: Joi.string()
        .required()
        .label("Job Title")
        .messages({
            "string.empty": required,
        }),
    name: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .required()
        .min(3)
        .max(30)
        .custom((value, helpers) => {
            if (value.trim() === "") {
                return helpers.error("string.empty");
            }
            return value;
        }, "Name Validation")
        .label("Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.nameRequired": "Name is required",
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .lowercase()
        .label("Email")
        .required()
        .messages({
            "string.empty": required,
        }),
    mobileNumber: Joi.string()
        .required()
        .min(8)
        .max(12)
        .label("Primary Contact Number")
        .messages({
            "string.empty": required
        }),
    mobileNumberSecondary: Joi.string()
        .min(8)
        .max(12)
        .allow("")
        .label("Secondary Contact Number"),

    _cpd: Joi.string()
        .label("CPD ID")
        .allow(""),
    collegeName: Joi.string()
        .label("College Name")
        .allow(""),
    graduationYear: Joi.string()
        .label("Graduation Year")
        .required()
        .messages({
            "string.empty": required
        }),
    comments: Joi.string()
        .label("Comments")
        .allow(""),
    source: Joi.string()
        .label("Source")
        .allow(""),
    reference: Joi.string()
        .label("Reference")
        .allow(""),
    others: Joi.string()
        .label("Other Reference")
        .allow(""),
    resumes: Joi.string()
        .label("Resume")
        .allow(""),
    location: Joi.string()
        .label("Home Location")
        .optional()
        .allow(""),   
});

export const AddFullTimeAndConsultantCandidateValidation = Joi.object({
    jobId: Joi.string()
        .required()
        .label("Job Title")
        .messages({
            "string.empty": required,
        }),
    name: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .required()
        .min(3)
        .max(30)
        .custom((value, helpers) => {
            if (value.trim() === "") {
                return helpers.error("string.empty");
            }
            return value;
        }, "Name Validation")
        .label("Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.nameRequired": "Name is required",
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .lowercase()
        .label("Email")
        .required()
        .messages({
            "string.empty": required
        }),
    mobileNumber: Joi.string()
        .required()
        .min(8)
        .label("Primary Contact Number")
        .messages({
            "string.empty": required
        }),
    mobileNumberSecondary: Joi.string()
        .min(8)
        .allow("")
        .label("Secondary Contact Number"),

    _cpd: Joi.string()
        .label("CPD ID")
        .allow(""),
    collegeName: Joi.string()
        .label("College Name")
        .allow(""),
    graduationYear: Joi.string()
        .label("Graduation Year")
        .allow(""),
    comments: Joi.string()
        .label("Comments")
        .allow(""),
    source: Joi.string()
        .label("Source")
        .allow(""),
    reference: Joi.string()
        .label("Reference")
        .allow(""),
    others: Joi.string()
        .label("Other Reference")
        .allow(""),
    resumes: Joi.string()
        .label("Resume")
        .allow(""),
});