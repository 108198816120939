import { FC, SyntheticEvent } from "react";
import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import { capitalize as textCapitalize, displayName } from "../../../utilities/helper";

interface props {
    label: string | JSX.Element;
    name: string;
    disabled?: boolean;
    displayUserName?: boolean;
    capitalize?: boolean;
    keyUpperCase?: boolean;
    error?: boolean;
    helperText?: string;
    size?: "small" | "medium";
    displayFieldKey: string;
    storeFieldKey: string;
    // eslint-disable-next-line
    setValue: (key: any, value: any) => void;
    // eslint-disable-next-line
    getValues: (key: any) => void;
    // eslint-disable-next-line
    trigger: (key: any) => void;
    // eslint-disable-next-line
    options: any;
}

const SearchSelect: FC<props> = ({
    label,
    name,
    disabled,
    displayUserName,
    capitalize,
    keyUpperCase,
    error,
    helperText,
    displayFieldKey,
    storeFieldKey,
    setValue,
    getValues,
    trigger,
    options,
    size
}) => {
    // eslint-disable-next-line
    const onChangeAutoComplete = (event: SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason,) => {
        switch (reason) {
            case "selectOption": {
                if (keyUpperCase) {
                    setValue(name, String(value[storeFieldKey]).toUpperCase());
                } else {
                    setValue(name, value[storeFieldKey]);
                }
                break;
            }
            case "clear":
                setValue(name, "");
                break;
            case "removeOption":
                setValue(name, "");
                break;
        }
        trigger(name);
    };

    // eslint-disable-next-line
    const getOption = (option: any) => {
        if (displayUserName) {
            return textCapitalize(displayName(option));
        }

        let value = option;
        if (displayFieldKey.includes(".")) {
            const keys = displayFieldKey.split(".");
            keys.forEach(key => {
                value = value[key];
            });
        } else {
            value = value[displayFieldKey];
        }


        return capitalize ? textCapitalize(value) : (value);
    };

    return (
        <Autocomplete
            fullWidth
            disabled={disabled}
            className="disable-text"
            options={options || []}
            disableClearable
            getOptionLabel={(option) => getOption(option)}
            renderInput={(params) => <TextField
                {...params}
                error={error}
                size={size? size: "small"}
                helperText={helperText}
                label={label}
                placeholder="Search..."
                disabled={disabled}
            />}
            // eslint-disable-next-line
            value={(options && options.find((option: any) => {
                if (keyUpperCase) {
                    return String(option[storeFieldKey]).toUpperCase() === String(getValues(name)).toUpperCase();
                }
                return option[storeFieldKey] === getValues(name);
            })) || null}
            onChange={onChangeAutoComplete}
            renderOption={(props, option) => (
                <li {...props} key={option[storeFieldKey]}>
                    { getOption(option)}
                </li>
            )}
        />
    );
};

export default SearchSelect;