import React, { useState } from "react";
import { Grid, Card, CardContent, Typography, Chip, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { TestSessionService } from "../../../../services";
import { capitalize, checkTestStatusBackgroundColor, checkTestStatusColor } from "../../../../utilities/helper";
import  "./style.scss";
import CopiedTextDisplay from "../copied-text";

const AssessmentResponseResult: React.FC = () => {
    const { testId } = useParams();
    const { getTestSession } = TestSessionService();
    const assignedAssessments = useQuery({
        queryKey: ["assigned-assessments"],
        queryFn: () => getTestSession({ _id: testId })
    });
    const [showModal, setShowModal] = useState(false);
    const onChangeModal = ()=> { setShowModal((prev)=>!prev); };
    return (
        <>
        {
            showModal && <CopiedTextDisplay
            session_id={assignedAssessments?.data?.data?._id || ""}
            onChangeModal= {onChangeModal}
            isOpen= {showModal}
            />
        }
            <div className="data-container">
                <div className="cardContainer1">
                    <div className="cardContainer2" >
                        <fieldset className="fieldset">
                            <legend className="legend">Overall</legend>
                            <div className="cardContainer">
                                <div>
                                    <Typography variant="h4" component="div" color="success.main" className="cardContentHeading">
                                        <strong>{assignedAssessments?.data?.data?.overallScores?.obtained.toFixed() ?? "--"}/{assignedAssessments?.data?.data?.overallScores?.total ?? "--"}</strong>
                                    </Typography>
                                </div>
                                <div className="cardContentMarks">
                                    <Typography color="black">Marks </Typography>
                                    <div className="cardContent cardContentFirst">
                                        <Typography color="textSecondary">Percentage: <span className="blackText">{(assignedAssessments?.data?.data?.overallScores?.percentage)?.toFixed(2) ?? ""}%</span></Typography>
                                        <Typography color="textSecondary">Duration: <span className="blackText">{(assignedAssessments?.data?.data?.duration.hours ?? 0) + "h " + (assignedAssessments?.data?.data?.duration.minutes ?? 0 ) + "m " + (assignedAssessments?.data?.data?.duration.seconds ?? 0 ) + "s"}</span></Typography>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div className="cardContainer1">
                    <div className="cardContainer2" >
                        <fieldset className="fieldset">
                            <legend className="legend">PSY</legend>
                            <div className="cardContainer">
                                <div>
                                <Typography variant="h4" component="div" className="cardContentHeading" color="success.main">
                                    <strong>{assignedAssessments?.data?.data?.psyOverAllScore?.obtained ?? "--"}/{assignedAssessments?.data?.data?.psyOverAllScore?.total ?? "--"}</strong>
                                </Typography>
                                </div>
                                <div className="cardContentMarks">
                                    <Typography color="black">Marks </Typography>
                                    <div className="cardContent">
                                        <Typography color="textSecondary">Percentage: <span className="blackText">{(assignedAssessments?.data?.data?.psyOverAllScore?.percentage)?.toFixed(2) ?? ""}%</span></Typography>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div className="cardContainer1">
                    <div className="cardContainer2" >
                        <fieldset className="fieldset">
                            <legend className="legend">Coding Challenge</legend>
                            <div className="cardContainer">
                                <div>
                                    <Typography variant="h4" component="div" className="cardContentHeading" color="success.main">
                                        <strong>{assignedAssessments?.data?.data?.challengesOverAllScore?.obtained.toFixed() ?? "--"}/{assignedAssessments?.data?.data?.challengesOverAllScore?.total ?? "--"}</strong>
                                    </Typography>
                                </div>
                                <div className="cardContentMarks">
                                    <Typography color="black">Marks </Typography>
                                    <div className="cardContent">
                                    <Typography color="textSecondary">Percentage: <span className="blackText">{(assignedAssessments?.data?.data?.challengesOverAllScore?.percentage)?.toFixed(0) ?? ""}%</span></Typography>
                                </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div className="cardContainer1">
                    <div className="cardContainer2" >
                        <fieldset className="fieldset">
                            <legend className="legend">Cheating</legend>
                            <div className="cheatingInfo">
                                <div>
                                    <Typography color="textSecondary">Leaving Tab: <span className="blackText cheatingInfoItem">{`${assignedAssessments?.data?.data?.tabSwitchCount} times`}</span></Typography>
                                    <Typography color="textSecondary">Copy Paste: <span className="blackText cheatingInfoItem">{!(assignedAssessments?.data?.data?.copyCount === 0)? `${assignedAssessments?.data?.data?.copyCount} times`: "Not Detected"}</span></Typography>
                                    <Button className="mt-1" variant="outlined" onClick={()=>setShowModal(true)} disabled= {!(assignedAssessments?.data?.data?.copyCount)}>View Copied Text</Button>                               
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>

            <Grid container className="gridContainer">
                {(assignedAssessments &&
                    assignedAssessments.data &&
                    assignedAssessments.data.data &&
                    assignedAssessments.data.data.psyResponses || []).map((test, testIndex) => (
                        <Grid container key={testIndex} className="categoriesContainer">
                            <Grid item xs={12}>
                                <Typography variant="h6" component="div">
                                    {capitalize(test.testName)}
                                </Typography>
                            </Grid>

                            <Grid className="categories">
                                {test.categoryWiseScore.map((cardNumber, categoryIndex) => (
                                    <Grid item xs={3} key={categoryIndex} className="gridItem">
                                        <Card variant="outlined">
                                            <CardContent className="cardContentFullHeight">
                                                <Typography variant="body1">{capitalize(cardNumber._category.name) ?? ""}</Typography>
                                                <div className="cardFooter">
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                        color="textSecondary"
                                                    >
                                                        <strong style={{ fontSize: "1.5em", color: "black" }}>
                                                            {cardNumber.obtained ?? "--"}
                                                        </strong>{" "}
                                                        /{cardNumber.total ?? "--"}
                                                    </Typography>
                                                    <Chip variant={"outlined"} sx={{ borderRadius: "4px", width: "110px", backgroundColor: checkTestStatusBackgroundColor(cardNumber.level) }} color={checkTestStatusColor(cardNumber.level)} label={capitalize(cardNumber.level)} />
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};

export default AssessmentResponseResult;
