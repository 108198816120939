import Joi from "joi";
import { required} from "../../shared";

export const jobtitleValidation = Joi.object({
    name: Joi.string()
        .min(3)
        .max(40)
        .trim()
        .required()
        .label("Name")
        .pattern(/^[^(){}[\]]*$/, "no brackets")
        .messages({
            "string.empty": required,
            "string.pattern.name": "Brackets are not allowed",
        }),
    _department: Joi.string()
        .required()
        .min(3)
        .trim()
        .label("Department")
        .messages({
            "string.empty": required
        }),
    _jobPosition: Joi.string()
        .required()
        .label("Designation")
        .messages({
            "string.empty": required
        }),
});
