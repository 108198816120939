import { Box, DialogContent, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import {
  Outlet,
  useOutletContext,
} from "react-router-dom";
import { ITest, ITestUserQuestions } from "../../../../../interfaces/configuration/tests";
import { IAssessmentPsyTestQuestionRow, IAssessmentPsyTestRow } from "../../../../../interfaces/configuration/assessments";
import CustomTable from "../../../../../components/mui/table";
import GetActions from "../../../../../components/get-actions";
import { TestsService } from "../../../../../services/configuration/tests";
import { useQuery } from "@tanstack/react-query";
import { formatDate, formatHtmlString } from "../../../../../utilities/helper";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../../components/shared/mui-tabs";


interface outletProps {
  refetchTests: () => void;
}
interface props {
  tests: ITest[];
}

const AssessmentPsyTests: FC<props> = ({ tests }) => {
  let rows: IAssessmentPsyTestRow[] = [];
  let testQuestionsRows: IAssessmentPsyTestQuestionRow[] = [];
  const [selectedTest, setSelectedTest] = useState("");
  const outlet = useOutletContext<outletProps>();
  const { getTestsQuestionList } = TestsService();

  const testQuestions = useQuery({
    queryKey: ["allTestQuestionList", selectedTest],
    queryFn: () =>
      getTestsQuestionList({
        _test: selectedTest,
        pagination: false
      }),
    enabled: !!selectedTest.length
  });

  const columns = [
    {
        id: "id",
        label: "S No.",
    },
    {
        id: "test_name",
        label: "Test Name",
    },
    {
        id: "test_description",
        label: "Test Description",
    },
    {
        id: "test_number_of_questions",
        label: "Number of Questions",
    },
    {
        id: "test_total_score",
        label: "Total Score",
    },
    {
        id: "test_duration",
        label: "Duration",
    },
    {
        id: "test_status",
        label: "Status",
    },
    {
        id: "action",
        label: "Action",
    },
  ];

  const testQuestionsColumns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "question",
      label: "Question",
    },
    {
      id: "category",
      label: "Category",
    },
    {
      id: "created",
      label: "Created At",
    },
  ];

  const createQuestionRow = (
    index: number,
    test: ITestUserQuestions
  ) => {
    const ques = <Tooltip title={test.question ? formatHtmlString(test.question) : ""}>
                    <span>
                    {test.question && test.question.length > 60
                        ? `${formatHtmlString(test.question).slice(0, 60)}...`
                        : formatHtmlString(test.question)}
                    </span>
                </Tooltip>;
    return {
      id: index + 1,
      question: ques,
      category: test._category.name ? test._category.name : "",
      created: formatDate(test.createdAt ? test.createdAt : "")
    };
  };
  if (
    testQuestions.data &&
    testQuestions.data.data &&
    testQuestions.data.data.length
  ) {
    testQuestionsRows = (
      (testQuestions.data &&
        testQuestions.data.data &&
        testQuestions.data.data) ||
      []
    ).map((test, i) => createQuestionRow(i, test));
  }

  const createRow = (
    index: number,
    test: ITest,
  ) => {
    const action = (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GetActions
              icons={[
                {
                  name: "View",
                  method: () => {
                    setSelectedTest(test._id);
                  }
                },
              ]}
            />
          </div>
        </>
      );

    const desc = <Tooltip title={test.description ? formatHtmlString(test.description) : ""}>
      <span>
      {test.description && test.description.length > 60
          ? `${formatHtmlString(test.description).slice(0, 60)}...`
          : formatHtmlString(test.description)}
      </span>
  </Tooltip>;

      return {
          id: index+1,
          test_name: test.name,
          test_description: desc,
          test_number_of_questions: test.numberOfQuestions,
          test_total_score: test.totalScore,
          test_duration: test.duration,
          test_status: test.status,
          action
      };
  };

  if (tests.length) {
    rows = ((tests) || []).map(
      (test, i) => createRow(i, test)
    );
  }

  return (
    <div id="test-manage">
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 260px)"
        />
      </Box>
        <BootstrapDialog
            maxWidth="md"
            onClose={() => {setSelectedTest("");}}
            open={!!selectedTest.length}
        >
            <BootstrapDialogTitle onClose={() => {setSelectedTest("");}}>
                <Box display="flex" alignItems="center">
                    PSY TEST QUESTIONS
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ height: "65vh" }}>
                <CustomTable
                    columns={testQuestionsColumns}
                    rows={testQuestionsRows}
                    height="calc(100vh - 345px)"
                />
            </DialogContent>
        </BootstrapDialog>
      <Outlet context={{ ...outlet }} />
    </div>
  );
};
export default AssessmentPsyTests;