import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { useEffect, useState, ChangeEvent, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import {
  capitalize,
  createIndex,
  formatDate,
} from "../../../../../utilities/helper";
import { IErrorResponse, IPagination, IStatus } from "../../../../../interfaces";
import Header from "../../../../../components/header";
import CustomTable from "../../../../../components/mui/table";
import GetActions from "../../../../../components/get-actions";
import useDebounce from "../../../../../hooks/useDebounce";
import {
  ITestUserRow,
} from "../../../../../interfaces/configuration/tests";
import { ITestSession } from "../../../../../interfaces/configuration/test-session"; 
import { useQuery } from "@tanstack/react-query";
import useResource from "../../../../../hooks/useResource";
import Filters from "./filter";
import { CandidateService, TestSessionService } from "../../../../../services";
import { IAssessmentResponsesState } from "../../../../../interfaces/configuration/assessments";
import { useSelector } from "react-redux";
import useSnackbar from "../../../../../hooks/useSnackbar";

const AssessmentResponse = () => {
  let rows: ITestUserRow[] = [];
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTestSessions  } = TestSessionService();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const { updateCandidateStatusAssignTo } = CandidateService();

  const [state, setState] = useState<IAssessmentResponsesState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      createdAt: {
        startDate: "",
        endDate: ""
      },
      _tests: [],
      _codingChallenges: [],
      status: [],
      submissionStatus: [],
      submissionDate: [],
    },
    filterCount: 0
  });

  const { assessmentId } = useParams();

  const tests = useQuery({
    queryKey: ["assessment-response", state.pagination.page, state.filters, state.sort],

    queryFn: () =>
      getTestSessions({
        _assessment: assessmentId,
        pagination: true,
        limit: 20,
        page: state.pagination.page,
        search: state.filters.search,
        startDate: state.filters.createdAt?.startDate,
        endDate: state.filters.createdAt?.endDate,
        ...state.sort,
        _tests: state.filters._tests,
        _codingChallenges: state.filters._codingChallenges,
        status: state.filters.status,
        submissionStatus: state.filters.submissionStatus,
        minScore: state.filters.score && state.filters.score.startScore,
        maxScore: state.filters.score && state.filters.score.endScore
      }),
  });

  const handleStatus = async (event: SelectChangeEvent<string>, _id: string) => {
    const { name, value } = event.target;

    try {
      const payload = {
        _id,
        [name]: value
      };
      const candidate = await updateCandidateStatusAssignTo(payload);
      snackbar(candidate.message, "info");
      tests.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });

      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        type: searchParams.get("type"),
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    tests.data &&
      tests.data &&
      tests.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: tests.data.meta.page,
          totalPages: tests.data.meta.totalPages,
          totalRecords: tests.data.meta.totalRecords,
        },
      }));
  }, [tests.data]);

  useEffect(() => {
    let filterCount = 0;
  
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
  
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date")
      ? JSON.parse(String(searchParams.get("date")))
      : [];
    const _tests: { key: string, value: string }[] = searchParams.get("_tests") ? JSON.parse(String(searchParams.get("_tests"))) : [];
    const _codingChallenges: { key: string, value: string }[] = searchParams.get("_codingChallenges") ? JSON.parse(String(searchParams.get("_codingChallenges"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const submissionStatus: { key: string, value: string }[] = searchParams.get("submissionStatus") ? JSON.parse(String(searchParams.get("submissionStatus"))) : [];
    const score: { startScore: number, endScore: number } = searchParams.get("score") ? JSON.parse(String(searchParams.get("score"))) : { startScore: undefined, endScore: undefined };

    filterCount += date.length ? 1 : 0;
    filterCount += _tests.length ? 1 : 0;
    filterCount += _codingChallenges.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += score.startScore || score.endScore ? 1 : 0;
    filterCount += submissionStatus.length ? 1 : 0;
  
    let createdAt = {
      startDate: "",
      endDate: "",
    };
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }
  
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page,
        },
        filters: {
          ...prevState.filters,
          search,
          createdAt,
          submissionStatus: submissionStatus.map(item => item.value),
          _tests: _tests.map(item => item.key),
          _codingChallenges: _codingChallenges.map(item => item.key),
          status: status.map(item => item.key),
          score: {
            startScore: score.startScore,
            endScore: score.endScore
          },
        },
        filterCount,
      }));
  }, [searchParams]);
  
  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleSortChange = (columnId: string, direction: "asc" | "desc" | "none") => {
    setState(prevState => ({
      ...prevState,
      sort: {
        [`${columnId}Sort`]: direction
      }
    }));
  };
  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "mobileNo",
      label: "Mobile No.",
    },
    {
      id: "marksObtained",
      label: "Marks Obtained",
      sortable: true
    },
    {
      id: "submitted",
      label: "Submitted",
      sortable: true
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const openDocument = (url: string | number | undefined) => {
    if (url && typeof url === "string" && url.includes("https://")) {
      return window.open(url, "_blank");
    } else {
      return window.open(String(process.env.REACT_APP_S3_BASE_URL) + url, "_blank");
    }
  };

  const createRow = (
    index: number,
    session: ITestSession,
    pagination: IPagination
  ) => {
    const paramsObj = Object.fromEntries(searchParams.entries());

    const search = new URLSearchParams({ ...paramsObj, subType: "results" }).toString();
    const action = <GetActions
    icons={[
      {
        name: "View", method: () =>  navigate({
          pathname: `response-details/${session?._id}/${session._lead._id}`,
          search: search
        }),
        disabled: !resourceAllocate("technical-session.read")
      },
      {
        name: "Preview", method: () =>  {openDocument(session.resultPdf);},
        disabled: !(session.resultPdf)
      }
    ]}
  />;
    const leadDetails = session && session._lead;
    const overallScores = session && session.overallScores;
    const status =
      <FormControl
          sx={{ width: 150 }}
      >
          <Select
              size="small"
              name="status"
              disabled={!resourceAllocate("lead.write")}
              onChange={e => handleStatus(e, session._lead._id)}
              value={session._lead.status}
          >
              <MenuItem disabled value="none">Select</MenuItem>
              <MenuItem value="CALL_NA">Called NA</MenuItem>
              <MenuItem value="NOT_INTERESTED">Not Interested</MenuItem>
              <MenuItem value="TBC_LATER">TBC Later</MenuItem>
              {
                  statusList
                      .filter(status => status.type === "CANDIDATE" && status.status === "ACTIVE")
                      .map((user, i) => <MenuItem key={i} value={user?.name.toUpperCase()}>{capitalize(user?.name)}</MenuItem>)
              }
          </Select>
      </FormControl>;
    return {
      id: createIndex(pagination, index),
      name: leadDetails.name ? capitalize(leadDetails.name) : "",
      email: session._lead.email ?? "",
      mobileNo: leadDetails.mobileNumber ? `${leadDetails.mobileNumber.dialCode} ${leadDetails.mobileNumber.number}` : "",
      marksObtained:
        String(overallScores?.obtained ?? 0) +
        "/" +
        String(overallScores?.total ?? 0),
      submitted: session.submittedDate ? formatDate(session.submittedDate) : "",
      status,
      action,
    };
  };

  if (tests.data && tests.data && tests.data.data.length) {
    rows = ((tests.data && tests.data.data && tests.data.data) || []).map((test, i) =>
      createRow(i, test, state.pagination)
    );
  }
  return (
    <div id="test-manage">
      <Header
        className="my-2"
        searchPlaceholder="Search by name, email or phone number"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        >
          <Filters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
        </Header>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 330px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
          onSortChange={handleSortChange}
        />
      </Box>
      <Outlet />
    </div>
  );
};

export default AssessmentResponse;
