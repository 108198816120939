import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useQuery } from "@tanstack/react-query";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useOutletContext } from "react-router-dom";
import { IErrorResponse } from "../../../../interfaces";
import { useState, useEffect } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { capitalize } from "../../../../utilities/helper";
import CustomLabel from "../../../../components/mui/custom-label";
import { ICategory } from "../../../../interfaces/content/category";
import React from "react";
import "./style.scss";
import ProjectsService from "../../../../services/content/projects-products";
import ProductMappingService from "../../../../services/content/product-mapping";
import { joiResolver } from "@hookform/resolvers/joi";
import { productMappingValidations } from "../../../../validations/content/product-mapping";

interface IOutletProps {
  reFetch: () => void;
}

interface IState {
  fields: IKeywordField[];
}

interface IKeywordField {
  name1: "product";
  type1: "select";
  label1: string;
  placeholder1: string;
  children1?: React.ReactNode;
  name2: "link";
  type2: "select";
  label2: string;
  placeholder2: string;
  name3: "field";
  type3: "select";
  label3: string;
  placeholder3: string;
  children3?: React.ReactNode;
  name4: "fallbackValue";
  type4: string;
  label4: string;
  placeholder4: string;
  name5: string;
  type5: string;
  label5: string;
  placeholder5: string;
}

interface IKeywordFieldForm {
  form: {
    _productId: string;
    link: string;
    token: string;
    mapping: { field: string; fallbackValue: string }[];
  };
}

const AddProductMapping = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { id } = useParams();
  const { getContentProjects } = ProjectsService();

  const outlet = useOutletContext<IOutletProps>();
  const { getProductMapping, addProductMapping, updateProductMapping } = ProductMappingService();

  const hitQuery = id === "new" ? false : true;

  const {
    handleSubmit,
    trigger,
    control,
    setValue,
    getValues,
    formState: { errors },
    reset
  } = useForm<IKeywordFieldForm>({
    resolver: joiResolver(productMappingValidations),
    defaultValues: {
      form:
      {
        _productId: "",
        link: "",
        token: "",
        mapping: [{ field: "", fallbackValue: "" }],
      },
    },
  });

  const mapping = useQuery({
    queryKey: ["product-mapping", id],
    queryFn: () =>
      getProductMapping({
        _id: id,
      }),
    enabled: hitQuery,
  });


  useEffect(() => {
    if (id !== "new") {
      if (mapping?.data?.data) {
        const newFields = [...initiateFields];
        setValue("form._productId", mapping?.data?.data?._productId?._id || "");
        trigger("form._productId");
        setValue("form.link", mapping?.data?.data?.link || "");
        setValue("form.token", mapping?.data?.data?.token || "");

        const mappingArray = mapping?.data?.data?.mapping;

        if (Array.isArray(mappingArray)) {
          mappingArray.forEach((data, index) => {
            if (index !== 0) {
              newFields.push({ ...initiateFields[0] });
            }

            setValue(`form.mapping.${index}.field`, data?.field || "");
            setValue(`form.mapping.${index}.fallbackValue`, data?.fallbackValue || "");
          });
        }
        setState((prev) => ({ ...prev, fields: newFields }));
      }
    }
  }, [mapping?.data?.data, id]);


  const dynamicFields =
    [
      { name: "Title", value: "title" },
      { name: "Slug", value: "slug" },
      { name: "Category", value: "_category" },
      { name: "Content", value: "content" },
      { name: "Read Time", value: "readTime" },
      { name: "Description", value: "description" },
      { name: "Meta Title", value: "metaTitle" },
      { name: "Meta Description", value: "metaDescription" },
      { name: "Keywords", value: "keywords" },
      { name: "Blog Image", value: "blogImage" },
      { name: "Author", value: "author" },
      { name: "Scheduled Date", value: "scheduledDate" }

    ];


  const productListData = useQuery({ queryKey: ["allCmsProject"], queryFn: () => getContentProjects({isActive: true}) });

  const productData = productListData?.data?.data;

  const initiateFields: IKeywordField[] = [
    {
      name1: "product",
      type1: "select",
      placeholder1: "Please Select Product",
      label1: "Product name",
      children1: productData?.map((product) => (
        <MenuItem key={product._id} value={product._id}>
          {product.name}
        </MenuItem>
      )),
      name2: "link",
      type2: "select",
      placeholder2: "Type Your Link",
      label2: "Type your link",
      name3: "field",
      type3: "select",
      label3: "Please Select field",
      placeholder3: "Select field",
      name4: "fallbackValue",
      type4: "select",
      label4: "Type your value",
      placeholder4: "Type your value",
      name5: "token",
      type5: "input",
      placeholder5: "Type your token",
      label5: "Type your token",
    },
  ];

  const [state, setState] = useState<IState>({
    fields: initiateFields,
  });

  const handleChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: ICategory | null
  ) => {
    if (value) {
      setValue("form._productId", value._id);
      trigger("form._productId");
    }
  };

  const onSubmit = async (data: IKeywordFieldForm) => {

    try {
      if (id === "new") {
        const response = await addProductMapping({ ...data.form });
        outlet.reFetch && outlet.reFetch();
        snackbar(response.message, "info");
        navigate("/content/product-mapping");
      } else {
        const response = await updateProductMapping({ ...data.form, _id: id });
        outlet.reFetch && outlet.reFetch();
        snackbar(response.message, "info");
        navigate("/content/product-mapping");
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in add Mapping", err);
    }
  };

  const addField = (index: number) => {
    const newFields = state.fields.concat(initiateFields[0]);
    setState((prev) => ({ ...prev, fields: newFields }));
    setValue(`form.mapping.${index + 1}.field`, "");
    setValue(`form.mapping.${index + 1}.fallbackValue`, "");
  };

  const removeField = (index: number) => {
    const newFields = state.fields.filter((field, i) => i !== index);
    setState((prev) => ({ ...prev, fields: newFields }));

    const payload = getValues();

    const updatedMapping = [...payload.form.mapping];

    updatedMapping.splice(index, 1);

    const size = updatedMapping.length;
    setValue("form.mapping", updatedMapping);
    for (let i = index; i < size - 1; i++) {
      setValue(`form.mapping.${i}.field`, updatedMapping[i]?.field);
      setValue(`form.mapping.${i}.fallbackValue`, updatedMapping[i]?.fallbackValue);
    }
  };



  const handleClose = () => {
    navigate("/content/product-mapping");
    setValue("form._productId", "");
    setValue("form.link", "");
    setValue("form.token", "");
    setValue("form.mapping", [{ field: "", fallbackValue: "" }]);
    reset();
  };

  return (
    <Box>
      <InlineDialog
        onClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {state.fields.map((field, index) =>
            <>
              {index === 0 && (
                <Grid item xs={6}>
                  <Controller
                    name={"form._productId"}
                    control={control}
                    render={(prop) => <Autocomplete
                      className="disable-text"
                      options={productData?.map(
                        (data) => data
                      ) || []}
                      clearIcon={null}
                      getOptionLabel={(option) => capitalize(option.name)}
                      renderInput={(params) => <TextField
                        {...params}
                        error={
                          errors["form"] &&
                            errors["form"]["_productId"]
                            ? true
                            : false
                        }
                        helperText={
                          errors["form"] &&
                          errors["form"]["_productId"] &&
                          errors["form"]["_productId"]?.message
                        }
                        size={"small"}
                        variant={"outlined"}
                        label={<CustomLabel label="Please Select Product" required />}
                        placeholder={field.placeholder1}
                      />}
                      {...prop.field}
                      value={productData?.find(product => product._id === getValues("form._productId")) || null}
                      onChange={(e, value) => {
                        handleChange(
                          e, value
                        );
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {capitalize(option.name)}
                        </li>
                      )}
                    />}
                  />
                </Grid>
              )}
              {index === 0 && (
                <Grid item xs={6}>
                  <Controller
                    name={"form.link"}
                    control={control}
                    render={(prop) => (
                      <TextField
                        label={<CustomLabel label="Link" required />}
                        placeholder={"Type Link Here"}
                        inputProps={{
                          maxLength: 100
                        }}
                        error={
                          errors["form"] &&
                            errors["form"]["link"]
                            ? true
                            : false
                        }
                        helperText={
                          errors["form"] &&
                          errors["form"]["link"] &&
                          errors["form"]["link"]?.message
                        }
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              )}
              {index === 0 && (
                <Grid item xs={12}>
                  <Controller
                    name={"form.token"}
                    control={control}
                    render={(prop) => (
                      <TextField
                      label={<CustomLabel label="Token" required />}
                        placeholder={"Type token Here"}
                        multiline={true}
                        minRows={3}
                        error={
                          errors["form"] &&
                            errors["form"]["token"]
                            ? true
                            : false
                        }
                        helperText={
                          errors["form"] &&
                          errors["form"]["token"] &&
                          errors["form"]["token"]?.message
                        }
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={`form.mapping.${index}.${field.name3}`}
                  render={(prop) => <Autocomplete
                    className="disable-text"
                    options={dynamicFields?.map(
                      (data) => data
                    ) || []}
                    clearIcon={null}
                    getOptionLabel={(option) => capitalize(option?.name)}
                    renderInput={(params) => <TextField
                      {...params}
                      error={
                        errors["form"] &&
                          errors["form"]["mapping"] &&
                          errors["form"]["mapping"][index] &&
                          errors["form"]["mapping"][index]?.[field.name3]
                          ? true
                          : false
                      }
                      helperText={
                        errors["form"] &&
                        errors["form"]["mapping"] &&
                        errors["form"]["mapping"][index] &&
                        errors["form"]["mapping"][index]?.[field.name3]?.message
                      }
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label="Please Select Field" required/>}
                      placeholder={field.placeholder3}
                    />}
                    {...prop.field}

                    value={dynamicFields?.find(product => product.value === getValues(`form.mapping.${index}.${field.name3}`)) || null}

                    onChange={(event, value) => {
                      setValue(`form.mapping.${index}.${field.name3}`, value?.value ?? "");
                      trigger(`form.mapping.${index}.${field.name3}`);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.value}>
                        {capitalize(option.name)}
                      </li>
                    )}
                  />}
                />
              </Grid>

              <Grid item xs={6} style={{ width: "100%" }}>
                <Box
                  display="flex"
                  alignItems="start"
                  flexDirection="column"
                >
                  <Controller
                    control={control}
                    name={`form.mapping.${index}.${field.name4}`}
                    render={(prop) => (
                      <>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          gap="7px"
                          mb={1}
                          width="100%"
                        >
                          <TextField
                            label={<CustomLabel label={field.label4} required/>}
                            placeholder={"Type Here"}
                            inputProps={{
                              maxLength: 30
                            }}
                            {...prop.field}
                            error={
                              errors["form"] &&
                                errors["form"]["mapping"] &&
                                errors["form"]["mapping"][index] &&
                                errors["form"]["mapping"][index]?.[field.name4]
                                ? true
                                : false
                            }
                            helperText={
                              errors["form"] &&
                              errors["form"]["mapping"] &&
                              errors["form"]["mapping"][index] &&
                              errors["form"]["mapping"][index]?.[field.name4]?.message
                            }
                          />
                          <div className="add-mores">
                            {state.fields.length > 1 && (
                              <Button
                                className="mr-1"
                                sx={{ padding: "7px 15px" }}
                                variant="outlined"
                                color="error"
                                onClick={() => removeField(index)}
                              >
                                <RemoveCircleIcon color="error" />
                              </Button>
                            )}
                            <Button
                              variant="outlined"
                              sx={{ padding: "7px 15px" }}
                              color="primary"
                              className="add-more-buttons"
                              onClick={() => addField(index)}
                            >
                              <AddCircleIcon color="primary" />
                            </Button>
                          </div>
                        </Box>
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>

      </InlineDialog>
    </Box>
  );
};

export default AddProductMapping;
