import HttpService from "../../http";
import { testSession } from "../../endpoints";
import {IAssignedTechnicalTestQuestionResponse, IAssignedTechnicalTestQuestionsResponse, IAssignedPsyTestQuestionsResponse, ITestSessionResponse, ITestSessionsResponse, ITestResponseResponse, ITestPsyTestResponse, ICopiedTextResponse } from "../../../interfaces";

const TestSessionService = () => {
    const { httpRequest } = HttpService();
    const createTestSession = async (payload: object): Promise<ITestSessionResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestSessionResponse>(
            "POST",
            `${testSession}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getTestSessions = async (search: object): Promise<ITestSessionsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestSessionsResponse>(
            "GET",
            `${testSession}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getAssignedTestSessions = async (search: object): Promise<ITestSessionsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestSessionsResponse>(
            "GET",
            `${testSession}/assigned-tests`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getAssignedTestPsyQuestions = async (search: object): Promise<IAssignedPsyTestQuestionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IAssignedPsyTestQuestionsResponse>(
            "GET",
            `${testSession}/psy-question-responses`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
      });

      const getCoderByteQuestions = async (query: object): Promise<IAssignedTechnicalTestQuestionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IAssignedTechnicalTestQuestionsResponse>(
            "GET",
            `${testSession}/coding-question-responses`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });


    const getCoderByteQuestion = async (query: object): Promise<IAssignedTechnicalTestQuestionResponse> => new Promise((resolve, reject) => {
        httpRequest<IAssignedTechnicalTestQuestionResponse>(
            "GET",
            `${testSession}/coding-question-response`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getTestSession = async (query: object): Promise<ITestSessionResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestSessionResponse>(
            "GET",
            `${testSession}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const runCode = async (payload: object): Promise<ITestResponseResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestResponseResponse>(
            "POST",
            `${testSession}/run-code`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPsyResponses = async (query: object): Promise<ITestPsyTestResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestPsyTestResponse>(
            "GET",
            `${testSession}/coding-question-responses/list`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getPsyResponsesTest = async (query: object): Promise<IAssignedPsyTestQuestionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IAssignedPsyTestQuestionsResponse>(
            "GET",
            `${testSession}/psy/assigned-test/questions`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });    
    
    const getCopiedText = async (query: object): Promise<ICopiedTextResponse> => new Promise((resolve, reject) => {
        httpRequest<ICopiedTextResponse>(
            "GET",
            `${testSession}/copiedText`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { createTestSession, getTestSession, getAssignedTestSessions, getAssignedTestPsyQuestions, getCoderByteQuestions, getCoderByteQuestion, getPsyResponses, runCode, getPsyResponsesTest, getCopiedText, getTestSessions };
};

export { TestSessionService };
