
import { Box, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import useResource from "../../../../hooks/useResource";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";
import { AssessmentService } from "../../../../services/configuration/assessments";
import { useQuery } from "@tanstack/react-query";
import Header from "../../../../components/header";
import { AssessmentEvaluationService } from "../../../../services/configuration/assessmentEvaluation";
import { IAssessmentEvaluationRuleData, IAssessmentRulesRow, IErrorResponse } from "../../../../interfaces";
import GetActions from "../../../../components/get-actions";
import { capitalize } from "../../../../utilities/helper";
import CustomTable from "../../../../components/mui/table";
import useSnackbar from "../../../../hooks/useSnackbar";
import WarningDialog from "../../../../components/mui/warning-dialog";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import CustomDialog from "../../../../components/mui/dialog";
import CustomLabel from "../../../../components/mui/custom-label";

interface outletProps {
  refetchRules: () => void;
}

const AssessmentEvaluationRules: FC = () => {
  let rows: IAssessmentRulesRow[] = [];
  const { assessmentId } = useParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const [state, setState] = useState({
    deleteWarning: false,
    _rule: "",
  });

  const [rulePosition, setRulePosition] = useState(false);
  const [ruleState, setRuleState] = useState({
    oldPosition: 0,
    _id: ""
  });

  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const { resourceAllocate } = useResource();
  const { getAssessment } = AssessmentService();
  const { getAssessmentRules, deleteAssessmentRule, updateRulePostion } = AssessmentEvaluationService();
  const assessmentDetails = useQuery({ queryKey: ["assessment-information"], queryFn: () => getAssessment({ _id: assessmentId }), enabled: resourceAllocate("assessment-evaluation-rule.read") });
  const assessmentName = assessmentDetails && assessmentDetails.data && assessmentDetails.data.data && assessmentDetails.data.data.title || "";

  const assessmentRulesDetails = useQuery({ queryKey: ["assessment-rules-information"], queryFn: () => getAssessmentRules({ _assessment: assessmentId }) });
  const assessmentRules = assessmentRulesDetails && assessmentRulesDetails.data && assessmentRulesDetails.data.data;

  const handleClick = () => {
    navigate("/configurations/assessments-evaluation");
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "ruleType",
      label: "Rule Type",
    },
    {
      id: "testType",
      label: "Test Type",
    },
    {
      id: "priority",
      label: "Priority",
    },
    {
      id: "department",
      label: "Departments"
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const handleDelete = (_rule = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _rule,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteAssessmentRule({ _id: state._rule, _assessment: assessmentId });
      snackbar(deleted.message, "info");
      handleDelete();
      assessmentRulesDetails.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  const { control, handleSubmit, formState: { errors }, setValue } = useForm<{ position: string }>({
    resolver: joiResolver(Joi.object({ position: Joi.number().required().label("Priority") })),
    defaultValues: {
      position: ""
    }
  });

  const handlePositonDialog = (_id: string, position: number) => {
    setValue("position", (position).toString());
    setRuleState({
      oldPosition: position,
      _id: _id,
    });
    setRulePosition(true);
  };

  const onPositionChange = async (data: {position: string}) => {
    const selectionRulesCount = assessmentRules && assessmentRules.filter((rule) => rule.ruleType === "SELECTION").length;
    if(Number(data.position) < 1 || (selectionRulesCount && Number(data.position) > selectionRulesCount)){
      snackbar(`Please enter a priority between 1 and ${selectionRulesCount}` , "error");
    }
    else{
      const payload = {
        oldPosition: ruleState.oldPosition,
        newPosition: Number(data.position),
        _id: ruleState._id,
        _assessment: assessmentId
      };
      try {
        const updated = await updateRulePostion( payload );
        snackbar(updated.message, "info");
        assessmentRulesDetails.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err?.data?.message, "error");
      }
      setRulePosition(false);
    }
  };

  const createRow = (index: number, ruleData: IAssessmentEvaluationRuleData) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              const updatedSearchParams = new URLSearchParams(searchParam);
              updatedSearchParams.set("type", ruleData.ruleType.toLowerCase());

              navigate({
                pathname: "" + ruleData._id,
                search: updatedSearchParams.toString(),
              });
            },
            disabled: !resourceAllocate("assessment-evaluation-rule.write")
          },
          {
            name: "Change Position",
            method: () => handlePositonDialog(ruleData?._id, ruleData?.position),
            disabled: !resourceAllocate("assessment-evaluation-rule.write") || ruleData.ruleType !== "SELECTION"
          },
          {
            name: "Delete",
            method: () => handleDelete(ruleData._id),
            disabled: !resourceAllocate("assessment-evaluation-rule.remove"),
          },
        ]}
      />
    );

    const department =
      <Tooltip title={ruleData?._departments?.map(data => data.name).join(", ")}>
        <span>
          {ruleData && ruleData?._departments && ruleData?._departments.length > 0 ? (
            <>
              {capitalize(ruleData._departments[0].name)}{" "}
              {ruleData._departments.length > 1 && (
                <span style={{ color: "blue" }}>+{ruleData._departments.length - 1}</span>
              )}
            </>
          ) : (
            ruleData.ruleType === "EVALUATION" ? "All" : "-"
          )}
        </span>
      </Tooltip>;


    return {
      id: index + 1,
      name: ruleData.name,
      ruleType: ruleData.ruleType || "",
      testType: ruleData.testType || "",
      department,
      priority: ruleData.ruleType === "SELECTION" ? String(ruleData.position) : "-",
      action,
    };
  };

  if (assessmentRules && assessmentRules.length) {
    rows = ((assessmentRules) || []).map(
      (data, i) => createRow(i, data)
    );
  }

  return (
    <div id="test-manage">
      <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography className="title" variant="h5">
            {assessmentName}
          </Typography>
        </div>
      </header>
      <Header
        className="my-2"
        btnText="ADD RULE"
        onBtnClick={
          resourceAllocate("assessment-evaluation-rule.write") ? () => navigate({ pathname: "new", search: "type=evaluation&" + searchParam.toString() }) : undefined
        }
      />

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 240px)"
          errorMessage="Add rules to see the data here"
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Rule "
        description="Are you sure you want to delete this rule?"
      />

      <CustomDialog
        title="Manage Priority Of Rules"
        isOpen={rulePosition}
        onClose={() => {
          setRulePosition(false);
          setValue("position", "");
        }}
        onSubmit={handleSubmit(onPositionChange)}
        size="sm"
      >
        <Grid item md={10}>
          <Controller
            control={control}
            name="position"
            render={(prop) => <TextField
              label={<CustomLabel label="Priority" required={true} />}
              variant="outlined"
              size="small"
              placeholder="Enter new priority"
              type="number"
              error={!!errors.position}
              helperText={errors["position"]?.message}
              {...prop.field}
            />}
          />
        </Grid>

      </CustomDialog>

      <Outlet context={{ ...outlet, reFetch: assessmentRulesDetails.refetch }} />
    </div>
  );
};
export default AssessmentEvaluationRules;