import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Grid, TextField, Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import CategoryCustomDialog from "../../../../components/mui/dialog/categoryDialog";
import CustomLabel from "../../../../components/mui/custom-label";
import useSnackbar from "../../../../hooks/useSnackbar";
import { CandidateService } from "../../../../services";
import { ICandidateExportFilterState, IErrorResponse } from "../../../../interfaces";
import { useEffect, useState } from "react";
import { capitalize } from "../../../../utilities/helper";

const ExportCandidate = () => {
    const { typeOfLead } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { snackbar } = useSnackbar();
    const { exportLead } = CandidateService();
    const [state, setState] = useState<ICandidateExportFilterState>({
        campusType: "both",
        status: [],
        source: [],
        assignTo: [],
        jobId: [],
        cpd: []
    });
    const { handleSubmit, trigger, formState: { errors }, setValue, watch } = useForm<{ fields: string[] }>({
        resolver: joiResolver(Joi.object({ fields: Joi.array().min(1).items(Joi.string()).required().label("Fields") })),
        defaultValues: {
            fields: [],
        },
    });

    useEffect(() => {
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const source: { key: string, value: string }[] = searchParams.get("source") ? JSON.parse(String(searchParams.get("source"))) : [];
        const cpds: { key: string, value: string }[] = searchParams.get("cpd") ? JSON.parse(String(searchParams.get("cpd"))) : [];
        const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
        const assignTo: { key: string, value: string }[] = searchParams.get("assign-to") ? JSON.parse(String(searchParams.get("assign-to"))) : [];
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        const experience: { isApply: boolean, value: number[] } = searchParams.get("experience") ? JSON.parse(String(searchParams.get("experience"))) : { isApply: false };
        const ctc: { isApply: boolean, value: number[] } = searchParams.get("ctc") ? JSON.parse(String(searchParams.get("ctc"))) : { isApply: false };
        const ectc: { isApply: boolean, value: number[] } = searchParams.get("ectc") ? JSON.parse(String(searchParams.get("ectc"))) : { isApply: false };
        const noticePeriod: { isApply: boolean, value: number[] } = searchParams.get("notice-period") ? JSON.parse(String(searchParams.get("notice-period"))) : { isApply: false };
        const reference: { key: string, value: string }[] = searchParams.get("reference") ? JSON.parse(String(searchParams.get("reference"))) : [];
        let createdAt: {
            startDate: string;
            endDate: string;
        } | undefined = undefined;
        if (date?.length) {
            createdAt = {
                startDate: date[0]?.startDate,
                endDate: date[0]?.endDate,
            };
        }


        let cpdKeys = cpds.map(cpd => cpd.key);
        const campusType = cpdKeys.filter(key => ["onCampus", "offCampus"].includes(key));
        cpdKeys = cpdKeys.filter(key => !["onCampus", "offCampus"].includes(key));
        setState(prevState => ({
            ...prevState,
            campusType: (campusType.length === 0 || campusType.length === 2) ? "both" : campusType[0],
            status: status.map(status => status.key),
            source: source.map(source => source.key),
            jobId: jobId.map(jobId => jobId.key),
            cpd: cpdKeys,
            assignTo: assignTo.map(assign => assign.key),
            reference: reference.map(ref => ref.key),
            createdAt,
            experience: experience?.isApply ? { startAmount: experience.value[0], endAmount: experience.value[1] } : undefined,
            ctc: ctc?.isApply ? { startAmount: ctc.value[0], endAmount: ctc.value[1] } : undefined,
            ectc: ectc?.isApply ? { startAmount: ectc.value[0], endAmount: ectc.value[1] } : undefined,
            noticePeriod: noticePeriod?.isApply ? { startAmount: noticePeriod.value[0], endAmount: noticePeriod.value[1] } : undefined,

        }));
    }, [searchParams]);

    const allFields = [
        { key: "jobId", value: "Job Title", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "name", value: "Name", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "email", value: "Email", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "typeOfLead", value: "Engagement Type", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "mobileNumber", value: "Primary Mobile Number", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "mobileNumberSecondary", value: "Secondary Mobile Number", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "_cpd", value: "CPD", applicableFor: ["intern"] },
        { key: "collegeName", value: "College Name", applicableFor: ["intern"] },
        { key: "degree", value: "Degree", applicableFor: ["intern"] },
        { key: "graduationYear", value: "Graduation Year", applicableFor: ["intern"] },
        { key: "resumes", value: "Resumes", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "reference", value: "Reference", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "location", value: "Work Location", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "currentLocation", value: "Home Location", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "source", value: "Source", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "firstSkill", value: "First skill", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "secondSkill", value: "Second skill", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "testScore", value: "Technical Assessment Score", applicableFor: ["intern"] },
        { key: "comments", value: "Comments", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "status", value: "Status", applicableFor: ["intern", "full-time", "consultant"] },
        { key: "ctc", value: "CTC", applicableFor: ["full-time", "consultant"] },
        { key: "ectc", value: "ECTC", applicableFor: ["full-time", "consultant"] },
        { key: "experience", value: "Experience", applicableFor: ["full-time", "consultant"] },
        { key: "lwd", value: "LWD", applicableFor: ["full-time", "consultant"] },
        { key: "noticePeriod", value: "Notice Period", applicableFor: ["full-time", "consultant"] },
    ];
    const fields = allFields.filter((field) => field.applicableFor.includes(typeOfLead || ""));

    const selectedFields = watch("fields");

    const closeExport = () => {
        navigate({ pathname: `/candidates/${typeOfLead}`, search: searchParams.toString() });
    };

    const handleOnExport = async () => {
        try {
            const payload = { projection: selectedFields, filter: state, typeOfLead };
            const response = await exportLead(payload);
            const byteArray = new Uint8Array(response?.data?.data);
            const today = new Date();
            const formattedDate = today.toISOString().split("T")[0];
            const fileName = `${capitalize(typeOfLead || "Candidate")} Data | ${formattedDate}`;

            const blob = new Blob([byteArray], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const file = new File([blob], fileName, {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const link = document.createElement("a");
            document.body.appendChild(link);

            link.href = window.URL.createObjectURL(file);
            link.download = fileName;
            link.click();
            document.body.removeChild(link);
            closeExport();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
        }
    };

    return (
        <CategoryCustomDialog
            size="sm"
            title="Export candidates information"
            isOpen={true}
            onClose={closeExport}
            confirmText="Export"
            onConfirm={handleSubmit(handleOnExport)}
            className="export-dialog"
        >
            <Grid container>
                <Grid key="category" item xs={11.3} margin={2}>
                    <Autocomplete
                        className="disable-text"
                        options={fields.map((data) => data) || []}
                        clearIcon={null}
                        getOptionLabel={(option) => capitalize(option.value)}
                        multiple
                        disableCloseOnSelect
                        value={fields.filter((field) => selectedFields.includes(field.key))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label={<CustomLabel label="Fields" required />}
                                placeholder="Please select fields"
                                error={!!errors["fields"]}
                                helperText={errors["fields"]?.message}
                            />
                        )}
                        onChange={(e, value) => {
                            setValue(
                                "fields",
                                value.map((option) => option.key)
                            );
                            trigger("fields");
                        }}
                        renderOption={(props, option) => {
                            const isSelected = selectedFields.includes(option.key);
                            return (
                                <li {...props} key={option.key}>
                                    <FormControlLabel
                                        key={option.key}
                                        control={
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={(e) => {
                                                    const newValue = e.target.checked
                                                        ? [...selectedFields, option.key]
                                                        : selectedFields.filter((field) => field !== option.key);
                                                    setValue("fields", newValue);
                                                }}
                                            />
                                        }
                                        label={capitalize(option.value)}
                                    />
                                </li>
                            );
                        }}
                        
                    />
                </Grid>
            </Grid>
        </CategoryCustomDialog>
    );
};

export default ExportCandidate;
