import React, { useState, useEffect } from "react";
import {
    Autocomplete,
    AutocompleteChangeReason,
    Box,
    Grid,
    MenuItem,
    TextField,
    Button
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { joiResolver } from "@hookform/resolvers/joi";
import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useQuery } from "@tanstack/react-query";
import CategoryService from "../../../../services/content/category";
import SubCategoryService from "../../../../services/content/sub-category";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useOutletContext } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IErrorResponse } from "../../../../interfaces";
import { capitalize } from "../../../../utilities/helper";
import quarterTargetService from "../../../../services/quarter-target";
import TypesOfContentService from "../../../../services/content/type-of-content";
import { QuarterTargetValidations } from "../../../../validations/planner/quarter-target";
import { ISubCategory } from "../../../../interfaces/content/sub-category";
import { ICategory } from "../../../../interfaces/content/category";
import CustomLabel from "../../../../components/mui/custom-label";
import "./style.scss";

interface IOutletProps {
    reFetch: () => void;
}

interface ICategoryData {
    _id: string;
    name: string
}

interface IQuarterTargetField {
    name1: string;
    type1: "select";
    label1: string;
    placeholder1: string;
    width1: number;
    children1?: React.ReactNode;
    name2: string;
    type2: "select";
    label2: string;
    placeholder2: string;
    children2?: React.ReactNode;
    width2: number;
}

interface IState {
    fields: IQuarterTargetField[];
    dynamicFields: {
        _id: string,
        name: string
    }[];
    children2: ISubCategory[];
    targets: {
        [key: string]: string
    }
}

interface IQuarterTargetFieldForm {
    form: {
        _category: string;
        _subCategory: string;
        targets: { [key: string]: string },
        children2: ISubCategory[]
    }[]
}

const ManageQuarterTargetForm = () => {
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { id, moduleId, quarterId } = useParams();
    const { getContentCategorys } = CategoryService();
    const outlet = useOutletContext<IOutletProps>();
    const { getContentSubCategorys } = SubCategoryService();
    const { getContentTypesOfContents } = TypesOfContentService();
    const { addQuarterTarget, updateQuarterTarget, getQuarterTarget } = quarterTargetService();
    const [index, setIndex] = useState<number>(0);
    const hitQuery = id === "new" ? false : true;

    const {
        unregister,
        handleSubmit,
        trigger,
        control,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<IQuarterTargetFieldForm>({
        resolver: joiResolver(QuarterTargetValidations),
        defaultValues: {
            form: [
                {
                    _category: "",
                    _subCategory: "",
                    children2: []
                },
            ],
        },
    });

    const [selectedCategoryId, setSelectedCategoryId] = useState<ICategoryData | null>(null);
    const target = useQuery({
        queryKey: ["keyword"],
        queryFn: () =>
            getQuarterTarget({
                _id: id,
            }),
        enabled: hitQuery,
    });

    const typeOfContent = useQuery({
        queryKey: ["typeOfContent"],
        queryFn: () => getContentTypesOfContents({})
    });

    useEffect(() => {
        if (typeOfContent?.data?.data) {
            setState((prev) => ({
                ...prev,
                dynamicFields: typeOfContent?.data?.data.map((type) => ({
                    name: type.name,
                    _id: type._id
                }))
            }));
            typeOfContent?.data?.data?.forEach((type) => {
                setValue(`form.${0}.targets.${type.name}`, "");
            });
        }
    }, [typeOfContent?.data?.data, target?.data]);

    useEffect(() => {
        if (id !== "new" && target?.data?.data) {
            setValue(`form.${0}._category`, target?.data?.data?._category?._id || "");
            setSelectedCategoryId(target?.data?.data?._category);
            subCategorys.refetch();
            state.dynamicFields.forEach((dynamicField) => {
                // Find the corresponding field in the target data
                const targetField = target?.data?.data?.targets?.find((target) => target?._id?._id === dynamicField._id);
                if (targetField) {
                    setValue(`form.${0}.targets.${dynamicField.name}`, String(targetField.count) ?? "");
                    trigger(`form.${0}.targets.${dynamicField.name}`);
                } else {
                    setValue(`form.${0}.targets.${dynamicField.name}`, "");
                }
            });
            setValue(`form.${0}._subCategory`, target?.data?.data?._subCategory?._id || "");
        }
    }, [target?.data]);

    const categorys = useQuery({
        queryKey: ["category"],
        queryFn: () =>
            getContentCategorys({
                isActive: true
            }),
        onSuccess: (data) => {
            const newarr = [...state.fields];
            const categoryFieldIndex = newarr.findIndex(
                (field) => field.name1 === "_category"
            );
            if (categoryFieldIndex !== -1) {
                newarr[categoryFieldIndex].children2 = data.data.map((category) => (
                    <MenuItem key={category?._id} value={category?._id}>
                        {category?.name}
                    </MenuItem>
                ));
                setState((prev) => ({ ...prev, fields: newarr }));
            }
        },
    });

    const subCategorys = useQuery({
        queryKey: [selectedCategoryId?._id, index],
        queryFn: () =>
            getContentSubCategorys({
                categoryId: [selectedCategoryId?._id]
            }),
        onSuccess: (data) => {
            setValue(`form.${index}.children2`, data?.data);
            const newarr = [...state.fields];
            const subCategoryFieldIndex = newarr.findIndex(
                (field) => field.name2 === "_subCategory"
            );
            if (subCategoryFieldIndex !== -1) {
                newarr[subCategoryFieldIndex].children2 = data.data.map(
                    (subCategory) => (
                        <MenuItem key={subCategory?._id} value={subCategory?._id}>
                            {subCategory?.name}
                        </MenuItem>
                    )
                );
                setState((prev) => ({ ...prev, fields: newarr }));
            }
        },
    });

    const categorysData = categorys?.data?.data;
    const subCategorysData = subCategorys?.data?.data;

    const initiateFields: IQuarterTargetField[] = [
        {
            name1: "_category",
            type1: "select",
            placeholder1: "Select Category",
            label1: "Please Select Category",
            width1: 4,
            children1: categorysData?.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                    {category.name}
                </MenuItem>
            )),
            name2: "_subCategory",
            type2: "select",
            label2: "Please Select Sub Category",
            placeholder2: "Select Sub Category",
            children2: subCategorysData?.map((subCategory) => (
                <MenuItem key={subCategory._id} value={subCategory._id}>
                    {subCategory.name}
                </MenuItem>
            )),
            width2: 4
        },
    ];

    const [state, setState] = useState<IState>({
        fields: initiateFields,
        dynamicFields: [],
        targets: {},
        children2: []
    });

    const onChangeUsersAutoComplete = (
        event: React.SyntheticEvent<Element, Event>,
        value: ISubCategory | null,
        reason: AutocompleteChangeReason,
        index: number
    ) => {
        if (value) {
            setValue(`form.${index}._subCategory`, value?._id || "");
            trigger(`form.${index}._subCategory`);
        }
    };

    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: ICategory | null,
        reason: AutocompleteChangeReason,
        index: number
    ) => {
        if (value) {
            setValue(`form.${index}._category`, value._id);
            trigger(`form.${index}._category`);
            setValue(`form.${index}._subCategory`, "");
            setSelectedCategoryId(value);
            setIndex(index);
        }
    };


    const onSubmit = async (data: IQuarterTargetFieldForm) => {

        const updatedPayloadArray = data.form.map((formData) => {
            const { _category, _subCategory } = formData;

            const dynamicFieldsData = state.dynamicFields.flatMap(dynamicField => {
                const value = formData.targets[dynamicField?.name];
                if (value) {
                    return {
                        _id: dynamicField._id,
                        count: value
                    };
                } else {
                    return null;
                }
            }).filter(Boolean);

            const updatedPayload = {
                _quarterId: quarterId,
                _category,
                _subCategory,
                targets: dynamicFieldsData
            };

            return updatedPayload;
        });

        try {
            if (id === "new") {
                const response = await addQuarterTarget(updatedPayloadArray);
                outlet.reFetch && outlet.reFetch();
                snackbar(response.message, "info");
                navigate(`/planner/${moduleId}/manage/${quarterId}`);
            } else {
                const response = await updateQuarterTarget({ ...updatedPayloadArray[0], _id: id });
                outlet.reFetch && outlet.reFetch();
                snackbar(response.message, "info");
                navigate(`/planner/${moduleId}/manage/${quarterId}`);
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            console.log("Error in add keyword", err);
        }
    };

    const addField = (index: number) => {
        const fieldPayload = state.fields;
        fieldPayload.splice(index + 1, 0, initiateFields[0]);
        setState((prev) => ({ ...prev, fields: fieldPayload }));

        const payload = getValues();
        let i = payload.form.length - 1;

        if (payload.form.length - 1 !== index) {
            while (i > index) {
                const nextIndexVAl = payload.form[i];

                setValue(`form.${i + 1}._category`, nextIndexVAl._category);
                setValue(`form.${i + 1}._subCategory`, nextIndexVAl._subCategory);
                --i;
            }
        }
        setValue(`form.${index + 1}._category`, "");
        setValue(`form.${index + 1}._subCategory`, "");
    };

    const removeField = (index: number) => {
        const fieldPayload = state.fields;
        fieldPayload.splice(index, 1);
        setState((prev) => ({
            ...prev,
            fields: fieldPayload,
        }));
        const payload = getValues();
        const size = payload.form.length;
        for (let i = index; i < size - 1; i++) {
            setValue(`form.${i}._category`, payload.form[i + 1]._category);
            setValue(`form.${i}._subCategory`, payload.form[i + 1]._subCategory);
        }
        unregister(`form.${size - 1}`);
        const formPayload = getValues().form.filter((val) => (val !== undefined));
        setValue("form", formPayload);
    };

    return (
        <Box>
            <InlineDialog
                onClose={() => navigate(`/planner/${moduleId}/manage/${quarterId}`)}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {state.fields.map((field, index) =>
                        <>
                            {/* Category  */}
                            <Grid item xs={5}>
                                <Controller
                                    control={control}
                                    name={`form.${index}._category`}
                                    render={(prop) => <Autocomplete
                                        className="disable-text"
                                        options={categorysData?.map(
                                            (data) => data
                                        ) || []}
                                        clearIcon={null}
                                        getOptionLabel={(option) => capitalize(option.name)}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            error={
                                                errors["form"] &&
                                                    errors["form"][index] &&
                                                    errors["form"][index]?.["_category"]
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors["form"] &&
                                                errors["form"][index] &&
                                                errors["form"][index]?.["_category"]?.message
                                            }
                                            size={"small"}
                                            variant={"outlined"}
                                            label={<CustomLabel label="Please Select Category" required />}
                                            placeholder={field.placeholder2}
                                        />}
                                        {...prop.field}
                                        value={categorysData?.find(category => category._id === getValues(`form.${index}._category`)) || null}
                                        onChange={(e, value, reason) => {
                                            handleChange(
                                                e, value, reason, index
                                            );
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option._id}>
                                                {capitalize(option.name)}
                                            </li>
                                        )}
                                    />}
                                />
                            </Grid>
                            {/* Sub Category  */}
                            <Grid item xs={5}>
                                <Controller
                                    control={control}
                                    name={`form.${index}._subCategory`}
                                    render={(prop) => <Autocomplete
                                        className="disable-text"
                                        options={
                                            getValues(`form.${index}.children2`)?.map(
                                                (subCategorysDa) => subCategorysDa
                                            ) || []
                                        }
                                        clearIcon={null}
                                        getOptionLabel={(option) => capitalize(option.name)}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            error={
                                                errors["form"] &&
                                                    errors["form"][index] &&
                                                    errors["form"][index]?.["_subCategory"]
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors["form"] &&
                                                errors["form"][index] &&
                                                errors["form"][index]?.["_subCategory"]?.message
                                            }
                                            size={"small"}
                                            variant={"outlined"}
                                            label={<CustomLabel label="Sub Category*" />}
                                            placeholder={"Please Select SubCategory"}
                                        />}
                                        {...prop.field}
                                        value={subCategorys?.data?.data?.find(subcategory => subcategory._id === getValues(`form.${index}._subCategory`)) || null}
                                        onChange={(e, value, reason) =>
                                            onChangeUsersAutoComplete(
                                                e,
                                                value,
                                                reason,
                                                index
                                            )
                                        }
                                        renderOption={(props, option) => (
                                            <li {...props} key={option._id}>
                                                {capitalize(option.name)}
                                            </li>
                                        )}
                                    />}
                                />
                            </Grid>
                            {id === "new" && (
                                <Grid className="add-mores" item xs={2}>
                                    {state.fields.length > 1 && (
                                        <Button
                                            sx={{ marginTop: "2px" }}
                                            className="mr-1"
                                            variant="outlined"
                                            color="error"
                                            onClick={() => removeField(index)}
                                        >
                                            <RemoveCircleIcon color="error" />
                                        </Button>
                                    )}
                                    {index === state.fields.length - 1 && <Button
                                        sx={{ marginTop: "2px" }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => addField(index)}
                                    >
                                        <AddCircleIcon color="primary" />
                                    </Button>
                                    }
                                </Grid>
                            )}
                            {/* Dynamic Fields */}
                            <Grid item xs={12}>
                                {state.dynamicFields.length > 0 &&
                                    <Grid container spacing={2}>
                                        {state.dynamicFields.map((dynamicField, dynamicIndex) => (
                                            <Grid item xs={1} key={dynamicIndex}>
                                                <Controller
                                                    control={control}
                                                    name={`form.${index}.targets.${dynamicField?.name}`}
                                                    render={(prop) => (
                                                        <TextField
                                                            {...prop.field}
                                                            label={dynamicField?.name}
                                                            className="disable-text"
                                                            placeholder={`Enter ${dynamicField?.name}`}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            type="number"
                                                            onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                                            inputProps={{
                                                                pattern: "[0-9]*",
                                                                inputMode: "numeric",
                                                                maxLength: 4,
                                                                onInput: (e) => {
                                                                    e.preventDefault();
                                                                    const inputValue = e.currentTarget.value;
                                                                    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                                                                    const truncatedValue = sanitizedValue.slice(0, 4);
                                                                    e.currentTarget.value = truncatedValue;
                                                                    prop.field.onChange(truncatedValue);
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            </Grid>

                        </>
                    )}
                </Grid>
            </InlineDialog>
        </Box>
    );
};

export default ManageQuarterTargetForm;
