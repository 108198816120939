import { FC, useEffect, useState } from "react";
import { ICollege, ICpdDashboardFilter } from "../../../../interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../components/shared/filter/style.scss";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useSelector } from "react-redux";
import { capitalize } from "../../../../utilities/helper";

interface Props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onClose: () => void;
}

const DashboardFilters: FC<Props> = ({ anchorEl, isOpen, onClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const colleges = useSelector<{ college: { list: ICollege[] } }, ICollege[]>(state => state.college.list);
  const [state, setState] = useState<ICpdDashboardFilter>({
    selectedMenu: 0,
    category: [],
    college: [],
  });
  
  useEffect(() => {
    const category: { key: string; value: string }[] = searchParams.get(
      "category"
    )
      ? JSON.parse(String(searchParams.get("category")))
      : [];
    const college: { key: string; value: string }[] = searchParams.get(
      "college"
    )
      ? JSON.parse(String(searchParams.get("college")))
      : [];
    setState((prevState) => ({
      ...prevState,
      category,
      college,
    }));
  }, [searchParams]);

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const handleRightListItem = (
    name: "category" | "college",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find((ele) => ele.key === key) ? true : false;
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const deleteChip = (name: "category" | "college", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      category: [],
      college: [],
    });
  };

  const onApply = () => {
    searchParams.set("category", JSON.stringify(state.category));
    searchParams.set("college", JSON.stringify(state.college));
    searchParams.set("page", "1");
    navigate({
      pathname: "/cpd/dashboard",
      search: searchParams.toString(),
    });
    onClose();
  };

  const OnClose = () => {
    const category: { key: string; value: string }[] = searchParams.get(
      "category"
    )
      ? JSON.parse(String(searchParams.get("category")))
      : [];
    const college: { key: string; value: string }[] = searchParams.get(
      "college"
    )
      ? JSON.parse(String(searchParams.get("college")))
      : [];
    setState((prevState) => ({
      ...prevState,
      category,
      college,
    }));
    onClose();
};

  const category = [
    {
      key: "tier-1",
      name: "Tier-1",
    },
    {
      key: "tier-2",
      name: "Tier-2",
    },
    {
      key: "tier-3",
      name: "Tier-3",
    },
  ];

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {(state.category.length || state.college.length) ? (
                <>
                  {state.category.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("category", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.college.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("college", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
              ) : (
                <Box
                  className="mt-2"
                  display="flex"
                  alignItems="center"
                >
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filter are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton
              onClick={OnClose}
              style={{ marginRight: "-10px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid
            className="filter-box"
            container
          >
            <Grid
              id="left"
              item
              xs={5}
            >
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Category"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state.category}
                />
                <MenuItem
                  index={1}
                  label="College"
                  selectedMenu={state.selectedMenu === 1}
                  onChange={handleLeftListItem}
                  count={state.college}
                />
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid
              id="right"
              item
              xs={6}
            >
              <List component="nav">
                {state.selectedMenu === 0 &&
                  category.map((category) => (
                    <ListItemButton
                      key={category.key}
                      selected={
                        state.category.find((ele) => ele.key === category.key)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRightListItem(
                          "category",
                          category.key,
                          category.name
                        )
                      }
                    >
                      <ListItemText primary={category.name} />
                      <Checkbox
                        edge="end"
                        checked={
                          state.category.find((ele) => ele.key === category.key)
                            ? true
                            : false
                        }
                      />
                    </ListItemButton>
                  ))}
                  {state.selectedMenu === 1 &&
                  <Autocomplete
                  options={colleges.map((college) => ({
                    label: college.name,
                    value: college._id
                  }))} 
                  getOptionLabel={(option) => capitalize(option.label)} 
                  onChange={(event, value) =>
                    handleRightListItem("college", value ? value.value : "", value ? value.label : "") 
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="College" variant="outlined" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {capitalize(option.label)}
                    </li>
                  )} 
                />
                  }
              </List>
            </Grid>
          </Grid>

          <Box
            className="actions-btn"
            marginTop="8px"
            textAlign="end"
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button
              className="ml-2"
              onClick={onApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default DashboardFilters;
