import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Switch, TextField, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { IResponseFilterState } from "../../../../../../interfaces/configuration/tests";
import { capitalize, checkTimePeriod } from "../../../../../../utilities/helper";
import { MenuItem } from "../../../../../../components/shared/filter";
import { TechnicalQuestionService } from "../../../../../../services";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { IStatus } from "../../../../../../interfaces";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import { TestsService } from "../../../../../../services/configuration/tests";


interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const Filters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { snackbar } = useSnackbar();
  const [state, setState] = useState<IResponseFilterState>({
    selectedMenu: 0,
    date: [],
    _tests: [],
    _codingChallenges: [],
    status: [],
    submissionStatus: [],
    submissionDate: [],
  });

  const [searchState, setSearchState] = useState({
    selectedMenu: 0,
    searchQuery: "",
  });

  const [applyScoreFilter, setApplyScoreFilter] = useState(
    !!state.score && !!state.score.startScore
  );

  const handleSearch = (event: { target: { value: string; }; }) => {
    setSearchState({ ...state, searchQuery: event.target.value });
  };

  const filterData = (data : {title: string, _id: string}[]) => {
    const query = searchState.searchQuery.trim().toLowerCase();
    return data.filter((item: { title: string; }) => item.title.toLowerCase().includes(query));
  };

  const { getTechnicalQuestions } = TechnicalQuestionService();
  const { getTests } = TestsService();
  const codingChallenges = useQuery({
    queryKey: ["allQuestionsData"],
    queryFn: () =>
      getTechnicalQuestions({}),
  });
  const codingChallengesData = codingChallenges?.data?.data;

  const tests = useQuery({
    queryKey: ["allTests"],
    queryFn: () =>
        getTests(),
  });
  const testsData = tests?.data?.data.map(test => ({
    _id: test._id,
    title: test.name
  }));

  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const statusListData = statusList.filter(status => (status.type === "CANDIDATE" && status.status === "ACTIVE" && status.name !== "APPLIED")).map(data => ({
    _id: data._id,
    title: data.name
  }));

  const submissionStatusList = [
    { value: true, key: "SUBMITTED" },
    { value: false, key: "NOT-SUBMITTED" },
  ];
  useEffect(() => {
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const _tests: { key: string, value: string }[] = searchParams.get("_tests") ? JSON.parse(String(searchParams.get("_tests"))) : [];
    const _codingChallenges: { key: string, value: string }[] = searchParams.get("_codingChallenges") ? JSON.parse(String(searchParams.get("_codingChallenges"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const submissionStatus: { key: string, value: string }[] = searchParams.get("submissionStatus") ? JSON.parse(String(searchParams.get("submissionStatus"))) : [];
    const score: { startScore: number, endScore: number } = searchParams.get("score") ? JSON.parse(String(searchParams.get("score"))) : { startScore: undefined, endScore: undefined };
    const dateFilter: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    let submissionDate: {
      startDate: string;
      endDate: string;
      key: string;
      value: string;
    }[] = [];
    if (dateFilter?.length) {
      submissionDate = [{
        key: dateFilter[0]?.key,
        value: dateFilter[0]?.value,
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      }];
    }
    setState(prevState => ({
      ...prevState,
      date,
      submissionStatus,
      submissionDate,
      _tests,
      _codingChallenges,
      status,
      score,
    }));
  }, [searchParams]);


  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setState(prevState => ({
      ...prevState,
      selectedMenu: index
    }));
    setSearchState({ selectedMenu: index, searchQuery: "" });
  };

  const handleDateListItem = (name: "date" | "submissionDate", key: string, value: string) => {
    const date = checkTimePeriod(key);

    const payload: Array<{
      key: string;
      value: string;
      startDate: string,
      endDate: string,
    }> = [{
      key,
      value,
      startDate: date.startDate,
      endDate: date.endDate,
    }];

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let date: Array<{
      key: string;
      value: string;
      startDate: string,
      endDate: string,
    }> = [];

    if (state.date.length && period === "start") {
      date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
    } else if (state.date.length && period === "end") {
      date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
    } else if (!state.date.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59);
      date = [{
        key: "custom",
        value: "custom",
        startDate: dayjs(e).startOf("day").toISOString(),
        endDate: currentDate.toISOString()
      }];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      date = [{
        key: "custom",
        value: "custom",
        startDate: currentDate.toISOString(),
        endDate: dayjs(e).endOf("day").toISOString(),
      }];
    }

    setState(prevState => ({
      ...prevState,
      date
    }));
  };

  const deleteChip = (name: "date" | "submissionStatus" | "status" | "_tests" | "_codingChallenges", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter(ele => ele.key !== key);

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      date: [],
      _tests: [],
      _codingChallenges: [],
      status: [],
      submissionStatus: [],
      submissionDate: [],
      score: {
        startScore: undefined,
        endScore: undefined
      }
    });
  };

  const onClose = () => {
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const _tests: { key: string, value: string }[] = searchParams.get("_tests") ? JSON.parse(String(searchParams.get("_tests"))) : [];
    const _codingChallenges: { key: string, value: string }[] = searchParams.get("_codingChallenges") ? JSON.parse(String(searchParams.get("_codingChallenges"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const submissionStatus: { key: string, value: string }[] = searchParams.get("submissionStatus") ? JSON.parse(String(searchParams.get("submissionStatus"))) : [];
    const score: { startScore: number, endScore: number } = searchParams.get("score") ? JSON.parse(String(searchParams.get("score"))) : { startScore: undefined, endScore: undefined };
    const dateFilter: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    let submissionDate: {
      startDate: string;
      endDate: string;
      key: string;
      value: string;
    }[] = [];
    if (dateFilter?.length) {
      submissionDate = [{
        key: dateFilter[0]?.key,
        value: dateFilter[0]?.value,
        startDate: dateFilter[0]?.startDate,
        endDate: dateFilter[0]?.endDate,
      }];
    }
    setState(prevState => ({
      ...prevState,
      date,
      _tests,
      _codingChallenges,
      status,
      submissionStatus,
      score,
      submissionDate
    }));
    OnClose();
  };

  const handleRightListItem = (name: "_tests" | "_codingChallenges" | "status" | "submissionStatus", key: string, value: string | boolean) => {
    let payload: Array<{
      key: string;
      value: string | boolean;
    }> = [];
    const isExist = !!state[name].find(ele => ele.key === key);
    if (isExist) {
      payload = state[name].filter(ele => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value
      });
    }


    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const onApply = () => {
    if (applyScoreFilter && (!state.score || !state.score.startScore || !state.score.endScore)) {
      snackbar("Both minimum and maximum score must be provided", "warning");
    }
    else if (state.score && ((state.score.startScore && !state.score.endScore) || (!state.score.startScore && state.score.endScore))) {
      snackbar("Both minimum and maximum score must be provided", "warning");
    } else if (state.score && state.score.startScore && state.score.endScore && (state.score.startScore > state.score.endScore)) {
      snackbar("Minimum score should be less than maximum score", "warning");
    } else {
      searchParams.set("date", JSON.stringify(state.date));
      searchParams.set("_tests", JSON.stringify(state._tests));
      searchParams.set("_codingChallenges", JSON.stringify(state._codingChallenges));
      searchParams.set("status", JSON.stringify(state.status));
      searchParams.set("submissionStatus", JSON.stringify(state.submissionStatus));
      searchParams.set("score", JSON.stringify(state.score));
      searchParams.set("submissionDate", JSON.stringify(state.submissionDate));
      searchParams.set("page", "1");
      navigate({
        pathname: "",
        search: searchParams.toString()
      });
      OnClose();
    }
  };

  const deleteScoreChip = () => {
    setState((prevState) => ({
      ...prevState,
      score: {
        startScore: undefined,
        endScore: undefined
      },
    }));
    setApplyScoreFilter(false);
  };

  return <>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={OnClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box id="filters-container">
        <Box className="center mb-3" justifyContent="space-between" alignItems="start">
          <div className="active-filter mb-1">
            {
              (state.date.length || state._codingChallenges.length || state.status.length || state.submissionStatus.length || state._tests.length || (!!state.score && !!state.score?.startScore)) ?
                <>
                  {state.date.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("date", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.submissionStatus.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("submissionStatus", ele.key)} label={ele.key} variant="outlined" />)}
                  {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}
                  {state._tests.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_tests", ele.key)} label={ele.value} variant="outlined" />)}
                  {state._codingChallenges.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_codingChallenges", ele.key)} label={ele.value} variant="outlined" />)}
                  {applyScoreFilter && <Chip className="m-1" color="primary" onDelete={() => deleteScoreChip()} label="Score Filter Applied" variant="outlined" />}

                </>
                :
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">No filters are applied</Typography>
                </Box>
            }
          </div>
          <IconButton
            onClick={onClose}
            style={{ marginRight: "-10px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid className="filter-box" container>
          <Grid id="left" item xs={5}>
            <List component="nav">

              <MenuItem
                index={0}
                label="Date"
                selectedMenu={state.selectedMenu === 0}
                onChange={handleLeftListItem}
                count={state.date}
              />

              <MenuItem
                index={1}
                label="Coding Challenges"
                selectedMenu={state.selectedMenu === 1}
                onChange={handleLeftListItem}
                count={state._codingChallenges}
              />

              <MenuItem
                index={2}
                label="MCQs"
                selectedMenu={state.selectedMenu === 2}
                onChange={handleLeftListItem}
                count={state._tests}
              />

              <MenuItem
                index={3}
                label="Score"
                selectedMenu={state.selectedMenu === 3}
                onChange={handleLeftListItem}
                count={applyScoreFilter ? [{ value: "score" }] : []}
              />

              <MenuItem
                index={4}
                label="Submission Status"
                selectedMenu={state.selectedMenu === 4}
                onChange={handleLeftListItem}
                count={state.submissionStatus}
              />
              <MenuItem
                index={5}
                label="Status"
                selectedMenu={state.selectedMenu === 5}
                onChange={handleLeftListItem}
                count={state.status}
              />

            </List>
          </Grid>
          <Divider orientation="vertical" />

          <Grid id="right" item xs={6}>
            <List component="nav">
              {
                state.selectedMenu === 0 &&
                <>
                  {[
                    { key: "yesterday", value: "Yesterday" },
                    { key: "today", value: "Today" },
                    { key: "thisWeek", value: "Weekly" },
                    { key: "thisMonth", value: "Monthly" },
                    { key: "thisQuarter", value: "Quarterly" },
                  ]?.map((date) =>
                    <ListItemButton
                      key={date.key}
                      selected={!!state.date.find(ele => ele.key === date.key)}
                      onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                    >
                      <ListItemText primary={date.value} />
                      <Checkbox edge="end" checked={!!state.date.find(ele => ele.key === date.key)} />
                    </ListItemButton>
                  )}
                  <Box marginTop={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        value={state.date[0]?.startDate ? dayjs(state.date[0]?.startDate) : null}
                        onChange={e => handleDate(e, "start")}
                        label="Start Date"
                        format="LL"
                      />
                    </LocalizationProvider>
                    <div className="mt-3" />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        value={state.date[0]?.endDate ? dayjs(state.date[0]?.endDate) : null}
                        onChange={e => handleDate(e, "end")}
                        label="End Date"
                        format="LL"
                      />
                    </LocalizationProvider>
                  </Box>
                </>

              }

              {
                state.selectedMenu === 1 &&
                <>

                  <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchState.searchQuery}
                    onChange={handleSearch}
                    style={{ marginBottom: "16px" }}
                  />

                  {codingChallengesData && filterData(codingChallengesData).map((category: { _id: string; title: string; }) => <ListItemButton
                    key={category?._id}
                    selected={!!state._codingChallenges.find(ele => ele.key === category._id)}
                    onClick={() => handleRightListItem("_codingChallenges", category?._id, category.title)}
                  >
                    <ListItemText primary={capitalize(category?.title || "")} />
                    <Checkbox edge="end" checked={!!state._codingChallenges.find(ele => ele.key === category._id)} />
                  </ListItemButton>)}
                </>
              }
              {
                state.selectedMenu === 2 &&
                <>
                  <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchState.searchQuery}
                    onChange={handleSearch}
                    style={{ marginBottom: "16px" }}
                  />
                  {testsData && filterData(testsData).map((service: { _id: string; title: string; }) => <ListItemButton
                    key={service?._id}
                    selected={!!state._tests.find(ele => ele.key === service._id)}
                    onClick={() => handleRightListItem("_tests", service._id, service.title)}
                  >
                    <ListItemText primary={capitalize(service.title)} />
                    <Checkbox edge="end" checked={!!state._tests.find(ele => ele.key === service._id)} />
                  </ListItemButton>)}

                </>
              }

              {
                state.selectedMenu === 3 &&
                <>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", margin: "16px 0" }}>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={applyScoreFilter}
                          onChange={(e) => {

                            if (!e.target.checked) {

                              setState((prevState) => ({
                                ...prevState,
                                score: {
                                  startScore: undefined,
                                  endScore: undefined,
                                },
                              }));
                            }
                            setApplyScoreFilter(e.target.checked);
                          }
                          }
                        />
                      }
                      label="Apply Score Filter"
                    />

                    <TextField
                      type="number"
                      label="Minimum Score"
                      placeholder="Enter minimum score"
                      variant="outlined"
                      disabled={!applyScoreFilter}
                      value={state.score && state.score.startScore || ""}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          score: {
                            ...prevState.score,
                            startScore: Number(e.target.value),
                          },
                        }))
                      }
                    />
                    <TextField
                      type="number"
                      label="Maximum Score"
                      placeholder="Enter maximum score"
                      variant="outlined"
                      value={state.score && state.score.endScore || ""}
                      disabled={!applyScoreFilter}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          score: {
                            ...prevState.score,
                            endScore: Number(e.target.value),
                          },
                        }))
                      }
                    />
                  </Box>
                </>
              }
              {
                state.selectedMenu === 5 &&
                <>
                  <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchState.searchQuery}
                    onChange={handleSearch}
                    style={{ marginBottom: "16px" }}
                  />
                  {filterData(statusListData)
                    .map((status) =>
                      <ListItemButton
                        key={status._id}
                        selected={!!state.status.find(ele => ele.key === status.title.toUpperCase())}
                        onClick={() => handleRightListItem("status", status.title.toUpperCase(), capitalize(status.title))}
                      >
                        <ListItemText primary={capitalize(status.title)} />
                        <Checkbox edge="end" checked={!!state.status.find(ele => ele.key === status.title.toUpperCase())} />
                      </ListItemButton>
                    )}
                </>
              }
              {
                state.selectedMenu === 4 &&
                submissionStatusList.map(status => <ListItemButton
                  key={status.key}
                  selected={!!state.submissionStatus.find(ele => ele.key === status.key)}
                  onClick={() => handleRightListItem("submissionStatus", status.key, status.value)}
                >
                  <ListItemText primary={status.key} />
                  <Checkbox edge="end" checked={!!state.submissionStatus.find(ele => ele.key === status.key)} />
                </ListItemButton>)
              }
            </List>

          </Grid>
        </Grid>

        <Box className="actions-btn" marginTop="8px" textAlign="end">
          <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
          <Button className="ml-2" onClick={onApply}>Apply</Button>
        </Box>
      </Box>
    </Menu>
  </>;
};

export default Filters;