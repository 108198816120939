import HttpService from "../../http";
import { assessments } from "../../endpoints";
import { IAssessmentCategoriesResponse, IAssessmentRuleResponse, IAssessmentRulesResponse } from "../../../interfaces";

const AssessmentEvaluationService = () => {
	const { httpRequest } = HttpService();
	const getAssessmentCategories = async (query: object): Promise<IAssessmentCategoriesResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentCategoriesResponse>(
			"GET",
			`${assessments}/assessment-categories`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const addAssessmentRule = async (payload: object): Promise<IAssessmentRuleResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentRuleResponse>(
			"POST",
			"config-cms/assessments-rule",
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const updateAssessmentRule = async (payload: object): Promise<IAssessmentRuleResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentRuleResponse>(
			"PUT",
			"config-cms/assessments-rule",
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentRule = async (query: object): Promise<IAssessmentRuleResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentRuleResponse>(
			"GET",
			"config-cms/assessments-rule",
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentRules = async (query: object): Promise<IAssessmentRulesResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentRulesResponse>(
			"GET",
			"config-cms/assessments-rule/list",
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteAssessmentRule = async (payload: object): Promise<IAssessmentRulesResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentRulesResponse>(
			"DELETE",
			"config-cms/assessments-rule",
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const updateRulePostion = async (payload: object): Promise<IAssessmentRuleResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentRuleResponse>(
			"PUT",
			"config-cms/assessments-rule/position-button",
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	return { getAssessmentCategories, addAssessmentRule, getAssessmentRules, deleteAssessmentRule,getAssessmentRule, updateAssessmentRule, updateRulePostion };
};

export { AssessmentEvaluationService };
