import HttpService from "../http";
import { ICandidatesResponse, ICandidateResponse, IQuery, ILeadSampleResponse, IWhatsappResponse, ICandidatesImportResponse, ILeadExportResponse } from "../../interfaces";
import { lead } from "../endpoints";

const CandidateService = () => {
    const { httpRequest } = HttpService();
    const addCandidate = async (payload: object): Promise<ICandidatesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidatesResponse>(
            "POST",
            `${lead}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addCandidates = async (payload: object): Promise<ICandidatesImportResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidatesImportResponse>(
            "POST",
            `${lead}/create-many`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getCandidates = async (params: IQuery, data = {}): Promise<ICandidatesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidatesResponse>(
            "POST",
            `${lead}/list`,
            data,
            params
        )
            .then(resolve)
            .catch(reject);
    });

    const getCandidatePartial = async (search: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "GET",
            `${lead}/partial`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCandidate = async (search: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "GET",
            lead,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCandidate = async (payload: object,): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "PUT",
            lead,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCandidateStatusAssignTo = async (payload: object,): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "PUT",
            `${lead}/status-assignto`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCandidate = async (payload: object,): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "DELETE",
            lead,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addCandidateCallStatus = async (payload: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "POST",
            `${lead}/call-status`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addCandidateNote = async (payload: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "POST",
            `${lead}/note`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCandidateNote = async (payload: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "PUT",
            `${lead}/note`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCandidateNote = async (search: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "DELETE",
            `${lead}/note`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const sendEmail = async (payload: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "POST",
            `${lead}/email`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const sendSms = async (payload: object): Promise<ICandidateResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateResponse>(
            "POST",
            `${lead}/sms`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const sendWhatsapp = async (payload: object): Promise<IWhatsappResponse> => new Promise((resolve, reject) => {
        httpRequest<IWhatsappResponse>(
            "POST",
            `${lead}/whatsapp`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getNewJoinee = async (search: object): Promise<ICandidatesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidatesResponse>(
            "GET",
            `${lead}/many`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getLeadSample = async (payload: object): Promise<ILeadSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ILeadSampleResponse>(
                "GET",
                `${lead}/sample`,
                {},
                payload
            )
                .then(resolve)
                .catch(reject);
        });

        const exportLead = async (payload: object): Promise<ILeadExportResponse> =>
            new Promise((resolve, reject) => {
                httpRequest<ILeadExportResponse>(
                    "POST",
                    `${lead}/export-excel`,
                    payload
                )
                    .then(resolve)
                    .catch(reject);
            });


    return {
        addCandidate,
        addCandidates,
        getCandidates,
        getCandidate,
        getCandidatePartial,
        updateCandidate,
        updateCandidateStatusAssignTo,
        deleteCandidate,
        addCandidateCallStatus,
        addCandidateNote,
        updateCandidateNote,
        deleteCandidateNote,
        sendEmail,
        sendSms,
        sendWhatsapp,
        getNewJoinee,
        getLeadSample,
        exportLead
    };
};

export { CandidateService };
