import Joi from "joi";
import { required} from "../../shared";  

export const assessmentEvaluationValidation = Joi.object({
  name: Joi.string()
    .trim()
    .min(3)
    .max(100)
    .required()
    .label("Rule Name")
    .messages({
        "string.empty": required
    }),
  testType: Joi.string()
    .required()
    .label("Test Type")
    .messages({
        "string.empty": required
    }),
  _departments: Joi.array()
    .items(Joi.string())
    .optional(),
  _jobs: Joi.array()
    .items(Joi.string())
    .optional(),
  engagementType: Joi.array()
    .items(Joi.string())
    .optional()
});

export const assessmentRejectionValidation = Joi.object({
  name: Joi.string()
    .trim()
    .min(3)
    .max(100)
    .required()
    .label("Rule Name")
    .messages({
        "string.empty": required
    }),
  testType: Joi.string()
    .required()
    .label("Test Type")
    .messages({
        "string.empty": required
    }),
  rejectionCategories: Joi.array()
    .items(Joi.string())
    .optional(),
  selectionAction: Joi.string()
    .required()
    .label("Action")
    .messages({
        "string.empty": required
    }),
  selectionScore: Joi.string()
    .required()
    .label("Number")
    .messages({
        "string.empty": required
    }),
  selectionUpdateStatus: Joi.string()
    .required()
    .label("Update Status")
    .messages({
        "string.empty": required
    })
});