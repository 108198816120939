import { Box, Tooltip } from "@mui/material";
import { FC } from "react";
import {
  Outlet,
  useOutletContext,
} from "react-router-dom";
import { IAssessmentCodingQuestionRow } from "../../../../../interfaces/configuration/assessments";
import CustomTable from "../../../../../components/mui/table";
import { ITechnicalQuestion } from "../../../../../interfaces";
import { formatHtmlString } from "../../../../../utilities/helper";


interface outletProps {
  refetchTests: () => void;
}
interface props {
    codingQuestions: ITechnicalQuestion[];
}

const AssessmentCodingQuestions: FC<props> = ({ codingQuestions }) => {
  let rows: IAssessmentCodingQuestionRow[] = [];
  const outlet = useOutletContext<outletProps>();

  const columns = [
    {
        id: "id",
        label: "S No.",
    },
    {
        id: "question_title",
        label: "Title",
    },
    {
        id: "question_description",
        label: "Description",
    },
    {
        id: "question_difficulty",
        label: "Difficulty",
    },
    {
        id: "question_weightage",
        label: "Weightage",
    },
    {
        id: "question_status",
        label: "Status",
    },
  ];

  const createRow = (
    index: number,
    question: ITechnicalQuestion,
  ) => ({
    id: index+1,
    question_title: question.title,
    question_description: <Tooltip title={question.description ? formatHtmlString(question.description) : ""}>
                              <span>
                              {question.description && question.description.length > 60
                                  ? `${formatHtmlString(question.description).slice(0, 60)}...`
                                  : formatHtmlString(question.description)}
                              </span>
                          </Tooltip>,
    question_difficulty: question.difficulty,
    question_weightage: question.weightage,
    question_status: question.status
  });

  if (codingQuestions.length) {
    rows = ((codingQuestions) || []).map(
      (question, i) => createRow(i, question)
    );
  }

  return (
    <div id="test-manage">
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 260px)"
        />
      </Box>
      <Outlet context={{ ...outlet }} />
    </div>
  );
};
export default AssessmentCodingQuestions;