import { SyntheticEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {Box, MenuItem, Grid, TextField, Divider, Chip, Autocomplete, AutocompleteChangeReason, styled,} from "@mui/material";
import { ICpdDrive, IEngagement, IErrorResponse, IJobTitle, IRole, IStatus, ITemplate } from "../../../../interfaces";
import { IAutomation, IAutomationFields } from "../../../../interfaces/configuration/automation";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { useNavigate, useParams, useOutletContext, useSearchParams } from "react-router-dom";
import AutomationService from "../../../../services/configuration/automation";
import { capitalize } from "../../../../utilities/helper";
import { joiResolver } from "@hookform/resolvers/joi";
import { automationValidation } from "../../../../validations";
import { broadcastService } from "../../../../services";
import CustomLabel from "../../../../components/mui/custom-label";
import { AssessmentService } from "../../../../services/configuration/assessments";
import { IAssessment } from "../../../../interfaces/configuration/assessments";
 
interface IOutletProps {
  reFetch: () => void;
}
const StyledChip = styled(Chip)`
  &&& {
    margin: 5px;
  }`;

const ManageAutomation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<IOutletProps>();
  const [...engagementTypes] =
    useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(
      (state) => state.engagementType.list
    ) || [];
  let templates = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(
    (state) => state.template.list
  );
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(
    (state) => state.status.list
  );
  const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>(
    (state) => state.role.list
  );
  const drives = useSelector<{ cpdDrive: { list: ICpdDrive[] } }, ICpdDrive[]>(state => state.cpdDrive.list) || [];
  const { getAssessments } = AssessmentService();
  const { addAutomation, updateAutomation, getAutomation } =
    AutomationService();
  const { whatsappTemplates } = broadcastService();
  const getWhatsappTemplates = useQuery({
    queryKey: ["whatsappTemplates"],
    queryFn: () => whatsappTemplates({}),
  });
  const whatsappTemplatesList = (getWhatsappTemplates.data && getWhatsappTemplates.data.data) || [];
  const hitQuery = id !== "new";
  const automationData = useQuery({
    queryKey: [hitQuery],
    queryFn: () => getAutomation({ _id: id }),
    enabled: hitQuery,
  });
  const [...jobTitle] = useSelector<
    { jobTitle: { list: IJobTitle[] } },
    IJobTitle[]
  >((state) => state.jobTitle.list);

  const { handleSubmit, control, getValues, trigger, watch, formState: { errors }, setValue,} = useForm<IAutomation>({
    resolver: joiResolver(automationValidation),
    defaultValues: {
      title: "",
      module: "LEAD",
      communicationType: "EMAIL",
      scheduledType: "IMMEDIATELY",
      scheduledTime: "7",
      filterStatus: [],
      filterSource: ["manual"],
      filterJobType: ["TECHNICAL"],
      filterEngagementType: ["INTERN"],
      filterCategory: ["tier-1"],
      receiverType: "END_USER",
      receiverRole: "",
      jobTitle: [],
      updatedStatus: "",
      _template: "",
      _assessment: []
    },
  });
  const selectedCommunicationType = watch("communicationType");
  const selectedScheduledType = watch("scheduledType");
  const selectedModule = watch("module");
  const selectedReceiverType = watch("receiverType");

  const assessementList = useQuery({
    queryKey: ["assessments-list"],
    queryFn: () => 
      getAssessments({
        pagination: false,
        status: ["CREATED", "PUBLISHED"]
      }),
    enabled: selectedCommunicationType === "ASSESSMENT",
  });
  const assessmentsData = assessementList && assessementList.data && assessementList.data.data || [];

  const onClose = () => {
    if (searchParams.get("module")) {
      navigate({
        pathname: "/configurations/automation/list",
        search: searchParams.toString(),
      });
    } else {
      navigate({
        pathname: "/configurations/automation",
      });
    }
  };

  if (selectedCommunicationType === "EMAIL") {
    templates = templates.filter(
      (template) =>
        template.type === "email" &&
        (template.tag === "general" || template.tag === "coderbyte")
    );
  } else if (
    selectedCommunicationType === "WHATSAPP"
  ) {
    templates = templates.filter(
      (template) =>
        (template.tag === "general" || template.tag === "coderbyte")
    );
  }
  const handleTitleDeleteChip = (titleId: string) => {
    const titleData = getValues("jobTitle");
    const newData = titleData && titleData.filter((id) => id !== titleId);
    setValue("jobTitle", newData);
  };

  useEffect(() => {
    if (automationData && automationData.data && automationData.data.data) {
      const automation = automationData.data.data;
      if ( automation && automation.filters && automation.filters.status.includes("OPEN")) {
      setValue("filterStatus", ["ACTIVE"]);
      } else {
        setValue("filterStatus", (automation && automation.filters && automation.filters.status) || ["ACTIVE"]);
      }
      setValue("title", automation.title);
      setValue("module", automation.module);
      setValue("communicationType", automation.communicationType);
      if(automation.communicationType !== "ASSESSMENT"){
        setValue("_template", automation._template || "");
        trigger("_template");
      }else{
        setValue("_assessment", automation._assessment || []);
        trigger("_assessment");
      }
      setValue("scheduledType", automation.scheduled && automation.scheduled.type);
      if((automation.scheduled && automation.scheduled.numberOfDays)){
        setValue("scheduledTime", String(automation.scheduled.numberOfDays));
      }else if((automation.scheduled && automation.scheduled.numberOfHours)){
        setValue("scheduledTime", String(automation.scheduled.numberOfHours));
      }else{
        setValue("scheduledTime", "7");
      }
      setValue("unitOfTime", (automation.scheduled && automation.scheduled.unitOfTime) || "DAYS");
      setValue("filterSource",(automation.filters && automation.filters.source) || ["manual"]);
      setValue("filterJobType",(automation.filters && automation.filters.jobType) || ["TECHNICAL"]);
      setValue("filterEngagementType", (automation && automation.filters && automation.filters.engagementType) || ["INTERN"]);
      setValue("filterCategory", (automation && automation.filters && automation.filters.category) || ["tier-1",]);
      setValue("receiverType", automation.receiver.type);
      setValue("receiverRole",(automation &&automation.receiver &&automation.receiver.roles &&automation.receiver.roles[0]) ||"");
      setValue("updatedStatus", automation.updatedStatus);
      setValue("jobTitle", ((automation && automation.filters && automation.filters.jobTitle) || []).map((data) => data._id));
      setValue("cpdId", ((automation && automation.filters && automation.filters.cpdId) || []));
    }

    if (!searchParams.get("module")) {
      onClose();
    } else {
      const moduleName = searchParams.get("module") || "";
      if (moduleName === "candidate") {
        setValue("module", "LEAD");
      } else if (moduleName === "cpd") {
        setValue("module", "CPD");
      } else if (moduleName === "job") {
        setValue("module", "JOB");
      }
    }
  }, [automationData && automationData.data, searchParams]);

  useEffect(() => {
    const subscribe = watch((value, { name }) => {
      if(name === "filterEngagementType"){
        if(value.filterEngagementType && !value.filterEngagementType.includes("INTERN")){
          setValue("cpdId", []);
        }
      }
      if (value.communicationType === "WHATSAPP" && name === "_template") {
        const template = whatsappTemplatesList.find(
          (template) => template._id === value._template
        );
        setValue("whatsappTemplateName", template && template.configuration && template.configuration.name || "");
      }
    });
    return () => subscribe.unsubscribe();
  }, [watch, whatsappTemplatesList]);

  const onChangeJobTitleAutoComplete = ( event: SyntheticEvent<Element, Event>, value: IJobTitle[], reason: AutocompleteChangeReason ) => {
    const jobTitleData = getValues("jobTitle");
    const isExist = jobTitleData && jobTitleData.includes(value[0]?._id);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          jobTitleData && jobTitleData.push(value[0]?._id);
          setValue("jobTitle", jobTitleData);
        }
        break;
      default:
        setValue("jobTitle", []);
        break;
    }
    trigger("jobTitle");
  };

  const onChangeAssessmentAutoComplete = (
    event: SyntheticEvent<Element, Event>, 
    value: IAssessment[], 
    reason: AutocompleteChangeReason
  ) => {
    const currentValue = getValues("_assessment");
    const assessment: string[] = Array.isArray(currentValue)
      ? currentValue.map((item) => (typeof item === "string" ? item : item._id))
      : []; 
  
    const selectedId = value[0]?._id;
    const isExist = selectedId ? assessment.includes(selectedId) : false;
  
    switch (reason) {
      case "selectOption":
        if (selectedId && !isExist) {
          assessment.push(selectedId);
          setValue("_assessment", assessment); 
        }
        break;
      default:
        setValue("_assessment", []); 
        break;
    }
  
    trigger("_assessment"); 
  };

  const onChangeJobAutoComplete = ( event: SyntheticEvent<Element, Event>, value: string | null, reason: AutocompleteChangeReason
  ) => {
    switch (reason) {
      case "selectOption":
        setValue("updatedStatus", value ? value : "");
        break;
      case "removeOption":
        setValue("updatedStatus", "");
        break;
      case "clear":
        setValue("updatedStatus", "");
        break;
      default:
        setValue("updatedStatus", "");
    }
    trigger("updatedStatus");
  };

  const onSubmit = async (data: IAutomation) => {
    const payload = {
      ...data,
      filters: {
        ...(data.module === "LEAD"
          ? {
              status: data.filterStatus,
              source: data.filterSource,
              jobType: data.filterJobType,
              engagementType: data.filterEngagementType,
              cpdId: watch("filterEngagementType")?.includes("INTERN") ? data.cpdId : undefined,
              jobTitle: getValues("jobTitle"),
            }
          : data.module === "CPD"
          ? {
              status: data.filterStatus,
              category: data.filterCategory,
            }
          : {
              status: data.filterStatus?.includes("ACTIVE") ? ["OPEN"] : [],
            }),
      },
      receiver: {
        type: data.receiverType,
        ...(data.receiverType === "ROLE_BASED"
          ? {
              roles: [data.receiverRole],
            }
          : {}),
      },
      scheduled: {
        type: data.scheduledType,
        unitOfTime: data.unitOfTime,
        ...(data.scheduledType === "CUSTOM" &&
          data.unitOfTime === "DAYS" && { numberOfDays: data.scheduledTime }),
        ...(data.scheduledType === "CUSTOM" &&
          data.unitOfTime === "HOURS" && { numberOfHours: data.scheduledTime }),
      },
    };
    if(data.communicationType !== "ASSESSMENT"){
      delete payload._assessment;
    }else{
      delete payload._template;
    }
    delete payload.cpdId;
    delete payload.filterStatus;
    delete payload.filterSource;
    delete payload.filterJobType;
    delete payload.filterEngagementType;
    delete payload.jobTitle;
    delete payload.filterCategory;
    delete payload.receiverType;
    delete payload.receiverRole;
    delete payload.scheduledType;
    delete payload.scheduledTime;
    delete payload.unitOfTime;

    try {
      if (id === "new") {
        const response = await addAutomation(payload);
        snackbar(response.message, "info");
        onClose();
        outlet.reFetch && outlet.reFetch();
      } else {
        const updatedPayload = { ...payload, _id: id };
        const response = await updateAutomation(updatedPayload);
        snackbar(response.message, "info");
        onClose();
        outlet.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const sourceOptions = [
    { value: "website", label: "Website" },
    { value: "email", label: "Email" },
    { value: "manual", label: "Manual" },
    { value: "careers website", label: "Careers Website" },
    { value: "linkedin", label: "Linkedin" },
    { value: "internshala", label: "InternShala" },
    { value: "naukri", label: "Naukri" },
    { value: "relinns chatbot", label: "Relinns Chatbot" },
    { value: "whatsapp-bot", label: "Whatsapp-Bot" },
    { value: "excel file", label: "Excel File" },
    { value: "cpd", label: "CPD" }
  ];

  const configurationFields: IAutomationFields[] = [
    {
      label: "Title",
      name: "title",
      type: "input",
      required: true,
      placeholder: "Enter Automation Title"
    },
    {
      label: "Module",
      name: "module",
      type: "select",
      disabled: true,
      children: [
        <MenuItem key={"LEAD"} value="LEAD">Candidate</MenuItem>,
        <MenuItem key="CPD" value="CPD">Cpd</MenuItem>,
        <MenuItem key="JOB" value="JOB">Job</MenuItem>,
      ],
      required: true
    },
    {
      label: "Automation For",
      name: "communicationType",
      type: "select",
      children: selectedModule === "LEAD" ? [
        <MenuItem key={"EMAIL"} value="EMAIL">Email</MenuItem>,
        <MenuItem key="whatsapp" value="WHATSAPP">Whatsapp Notifications</MenuItem>,
        <MenuItem key="assessment" value="ASSESSMENT">Assessment</MenuItem>,
      ] : [
        <MenuItem key={"EMAIL"} value="EMAIL">Email</MenuItem>,
        <MenuItem key="whatsapp" value="WHATSAPP">Whatsapp Notifications</MenuItem>,
      ],
      required: true
    },
    {
      label: "Template",
      name: "_template",
      type: "select",
      children:
        selectedCommunicationType === "WHATSAPP"
          ? whatsappTemplatesList.map((template) => (
              <MenuItem key={template._id} value={template._id}>{template.configuration.name}</MenuItem>
            ))
          : templates.map((template) => (
              <MenuItem key={template._id} value={template._id}>{template.title}</MenuItem>
            )),
      required: true
    },
    {
      label: "Assessment",
      name: "_assessment",
      type: "multiSelect",
      required: true
    },
    {
      label: "Schedule",
      name: "scheduledType",
      type: "select",
      children: [
        <MenuItem key="IMMEDIATELY" value="IMMEDIATELY">Immediately</MenuItem>,
        <MenuItem key="CUSTOM" value="CUSTOM">Custom Day</MenuItem>,
      ],
      required: true
    },
    {
      label: "Days/Hours",
      name: "unitOfTime",
      type: "select",
      children: [
        <MenuItem key="DAYS" value="DAYS">Days</MenuItem>,
        <MenuItem key="HOURS" value="HOURS">Hours</MenuItem>,
      ],
      required: true
    },
    {
      label: "Updated Status",
      name: "updatedStatus",
      type: "autoComplete",
      options: statusList,
    },
    {
      label: "No. of days/hours",
      name: "scheduledTime",
      type: "input",
      required: true
    },
  ];

  const filtersFields: IAutomationFields[] = [
    {
      label: "Status",
      name: "filterStatus",
      type: "select",
      required: true,
      children:
        selectedModule === "LEAD"
          ? [
              <MenuItem key="APPLIED" value="APPLIED">Applied</MenuItem>,
              <MenuItem key="CALL_NA" value="CALL_NA">Called NA</MenuItem>,
              <MenuItem key="NOT_INTERESTED" value="NOT_INTERESTED">Not Interested</MenuItem>,
              <MenuItem key="TBC_LATER" value="TBC_LATER">TBC Later</MenuItem>,
              ...statusList
                .filter((status) => status.type === "CANDIDATE")
                .map((status) => status && (<MenuItem key={status._id} value={status.name}>{capitalize(status.name)}</MenuItem>)
                ),
            ]
          : selectedModule === "CPD"
          ? [...statusList.filter((status) => status && status.type === "CPD").map((status) => status && (<MenuItem key={status._id} value={status.name}>{capitalize(status.name)}</MenuItem>)),]
          : [<MenuItem key="ACTIVE" value="ACTIVE">Active</MenuItem>]
    },
    {
      label: "Source",
      name: "filterSource",
      type: "select",
      required: true,
      children: sourceOptions.map((source) => (
        <MenuItem key={source.value} value={source.value}>{source.label}</MenuItem>
      )),
    },
    {
      label: "Job Type",
      name: "filterJobType",
      type: "select",
      required: true,
      children: [
        <MenuItem key="TECHNICAL" value="TECHNICAL">
          Technical
        </MenuItem>,
        <MenuItem key="NON_TECHNICAL" value="NON_TECHNICAL">
          Non Technical
        </MenuItem>,
      ],
    },
    {
      label: "Engagement Type",
      name: "filterEngagementType",
      type: "select",
      required: true,
      children: engagementTypes.map((engagement) => (
        <MenuItem
          key={engagement.name}
          value={engagement && engagement.name && engagement.name.toUpperCase()}
        >
          {capitalize(engagement.name)}
        </MenuItem>
      )),
    },

    {
      label: "Category",
      name: "filterCategory",
      type: "select",
      required: true,
      children: [
        <MenuItem key="tier-1" value="tier-1">
          Tier 1
        </MenuItem>,
        <MenuItem key="tier-2" value="tier-2">
          Tier 2
        </MenuItem>,
        <MenuItem key="tier-3" value="tier-3">
          Tier 3
        </MenuItem>,
      ],
    },

    {
      name: "cpdId",
      label: "CPD",
      type: "select",
      children: drives.filter((drive) => drive.status === "CPD SCHEDULED").map((drive) => (
        <MenuItem
          key={drive.cpdId}
          value={drive.cpdId}
        >
          {drive.cpdId}
        </MenuItem>
      )),
      placeholder: "Select CPD here",
    },
  ];

  const receiverFields: IAutomationFields[] = [
    {
      label: "Receiver",
      name: "receiverType",
      type: "select",
      children: [
        <MenuItem key="END_USER" value="END_USER">End User</MenuItem>,
        <MenuItem key="ROLE_BASED" value="ROLE_BASED" disabled={selectedCommunicationType === "ASSESSMENT"}>Role Based</MenuItem>,
        <MenuItem key="CUSTOM" value="CUSTOM" disabled>Custom</MenuItem>,
      ],
      required: true,
    },
    {
      label: "Receiver Role",
      name: "receiverRole",
      type: "select",
      children: roles.map((role) => (
        <MenuItem key={role._id} value={role._id}>
          {capitalize(role.name)}
        </MenuItem>
      )),
      required: true
    },
  ];

  const filteredConfigurationFields = configurationFields.filter((field) => {
    if (selectedScheduledType === "IMMEDIATELY") {
      if (selectedModule === "JOB") {
        // If selectedScheduledType is "IMMEDIATELY" and selectedModule is not "candidate", not show the "updated" status
        return !["scheduledTime", "unitOfTime", "updatedStatus"].includes(field.name);
      } else if( selectedCommunicationType === "ASSESSMENT"){
        // If selectedScheduledType is "IMMEDIATELY" and selectedModule is "candidate", not show "scheduledTime" and "unitOfTime"
        return field.name !== "scheduledTime" && field.name !== "unitOfTime" && field.name !== "_template";
      } else {
        return field.name !== "scheduledTime" && field.name !== "unitOfTime" && field.name !== "_assessment";
      }
    } else {
      if (selectedModule === "JOB") {
        // If selectedScheduledType is not "IMMEDIATELY" and selectedModule is not "candidate", not show the "updated" status
        return field.name !== "updatedStatus";
      } else if( selectedCommunicationType === "ASSESSMENT"){
        // If selectedScheduledType is not "IMMEDIATELY" and selectedModule is "candidate", show all fields
        return field.name !== "_template";
      } else {
        return field.name !== "_assessment";
      }
    }
  });

  const filteredFilterFields = filtersFields.filter((field) => {
    if (selectedModule === "LEAD" && watch("filterEngagementType")?.includes("INTERN")) {
      return [ "filterStatus", "filterSource", "filterJobType", "filterEngagementType", "jobTitle", "cpdId"].includes(field.name);
    } else if(selectedModule === "LEAD" && !watch("filterEngagementType")?.includes("INTERN")){
      return [ "filterStatus", "filterSource", "filterJobType", "filterEngagementType", "jobTitle",].includes(field.name);
    }else if (selectedModule === "CPD") {
      return ["filterStatus", "filterCategory"].includes(field.name);
    } else if (selectedModule === "JOB") {
      return ["filterStatus"].includes(field.name);
    }
  });

  const filteredReceiverFields = receiverFields.filter((field) => {
    if (selectedReceiverType === "END_USER") {
      return ["receiverType"].includes(field.name);
    } else if (selectedReceiverType === "ROLE_BASED") {
      return ["receiverType", "receiverRole"].includes(field.name);
    } else if (selectedReceiverType === "CUSTOM") {
      return ["receiverType"].includes(field.name);
    }
  });

  return (
    <Box>
      <CustomDialog
        title={id === "new" ? "Add Automation" : "Edit Automation"}
        isOpen={!!id}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Chip variant="outlined" label="Automation Configuration" />
            </Divider>
          </Grid>
          {filteredConfigurationFields.map((field) => {
            if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            }
            else if (field.type === "multiSelect") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <>
                        <Autocomplete
                          className="disable-text"
                          options={
                            assessmentsData.map((assessment) => assessment) || []
                          }
                          getOptionLabel={(option) => capitalize(option.title)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label={
                                <CustomLabel
                                  label={field && field.label}
                                  required={field && field.required}
                                />
                              }
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                            />
                          )}
                          {...prop.field}
                          value={[]}
                        onChange={(e, value, reason) =>
                          onChangeAssessmentAutoComplete(e, value, reason)
                        }
                        multiple
                        />
                        {((getValues(field.name) as string[]) || []).map(
                          (item) => (
                            <StyledChip
                              key={item}
                              label={assessmentsData.find((assessment) => assessment._id === item)?.title}
                              color="primary"
                              onDelete={() => {
                                setValue(
                                  field.name,
                                  (getValues(field.name) as string[])?.filter(
                                    (value) => value !== item
                                  )
                                );
                              }}
                              variant="outlined"
                            />
                          ))}
                      </>
                    )}
                  />
                </Grid>
              );
            }
            else if (field.type === "autoComplete") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <Autocomplete
                        className="disable-text"
                        options={selectedModule === "LEAD" ? statusList.filter((status) => status.type === "CANDIDATE").map((status) => status.name) : statusList.filter((status) => status.type === "CPD").map((status) => status.name)}
                        getOptionLabel={(option) => capitalize(option)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors[field.name]}
                            helperText={errors[field.name] ? errors[field.name]?.message : ""}
                            label={<CustomLabel label="Updated Status" />}
                            size={"small"}
                            variant={"outlined"}
                            placeholder={field.placeholder}
                          />
                        )}
                        {...prop.field}
                        value={ statusList.map((status) => status.name).find((status) => status === getValues("updatedStatus")) || null}
                        onChange={onChangeJobAutoComplete}
                      />
                    )}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    size="small"
                    variant="outlined"
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    disabled={field && field.disabled}
                  >
                    {field.children}
                  </Select>
                </Grid>
              );
            }
          })}
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Chip variant="outlined" label="Automation Filters" />
            </Divider>
          </Grid>
          {filteredFilterFields.map((field) => {
            if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <>
                        <Autocomplete
                          className="disable-text"
                          options={((field && field.children) || []).map(
                            (menuItem) => menuItem && menuItem.props && menuItem.props.value
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label={
                                <CustomLabel
                                  label={field && field.label}
                                  required={field && field.required}
                                />
                              }
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                            />
                          )}
                          {...prop.field}
                          value={[]}
                          onChange={(event, newValues) => {
                            const values = (getValues(field.name) ||
                              []) as string[];

                            const newValuesArray = Array.isArray(newValues)
                              ? newValues
                              : [newValues];
                            const uniqueValues = newValuesArray.filter(
                              (value) => !values.includes(value)
                            );

                            const resultArray = [...values, ...uniqueValues];
                            setValue(field.name, resultArray);
                            trigger(field.name);
                          }}
                          multiple
                        />
                        {((getValues(field.name) as string[]) || []).map(
                          (item) => (
                            <StyledChip
                              key={item}
                              label={item}
                              color="primary"
                              onDelete={() => {
                                setValue(
                                  field.name,
                                  (getValues(field.name) as string[])?.filter(
                                    (value) => value !== item
                                  )
                                );
                              }}
                              variant="outlined"
                            />
                          ))}
                      </>
                    )}
                  />
                </Grid>
              );
            }
          })}

         {searchParams.get("module") === "candidate" && (
          <Grid item xs={12} md={6}>           
            <Box display="flex" alignItems="start" flexDirection="column">
              <Controller
                control={control}
                name={"jobTitle"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        fullWidth
                        options={
                          jobTitle.map((keywordData) => keywordData) || []
                        }
                        getOptionLabel={(option) => capitalize(option.name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={"Job Title"}
                            placeholder={"job Title"}
                            error={errors["jobTitle"] && true}
                            helperText={errors["jobTitle"]?.message}
                          />
                        )}
                        {...prop.field}
                        value={[]}
                        onChange={(e, value, reason) =>
                          onChangeJobTitleAutoComplete(e, value, reason)
                        }
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >
                      {getValues("jobTitle")?.map((jobTitleObjId) => (
                        <StyledChip
                          key={jobTitleObjId}
                          label={
                            jobTitle.find(
                              (title) => title._id === jobTitleObjId
                            )?.name
                          }
                          color="primary"
                          variant="outlined"
                          onDelete={() => handleTitleDeleteChip(jobTitleObjId)}
                        />
                      ))}
                    </Box>
                  </>
                )}
              />
            </Box> 
          </Grid> )}

          <Grid item xs={12}>
            <Divider textAlign="left">
              <Chip variant="outlined" label="Automation Receiver" />
            </Divider>
          </Grid>
          {filteredReceiverFields.map((field) => {
            if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    size="small"
                    variant="outlined"
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
              );
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};
export default ManageAutomation;